import { Box, FormControl, Grid, TextField, Typography, Icon } from '@mui/material';
import { useState, useEffect } from 'react';
import * as React from 'react';

function AddedCreatorsFilters(props){
    const [filters, setFilters] = useState({});

    useEffect(() => {
        setFilters(props.filters);
    }, []);

    useEffect(() => {
        if(filters.searched_name && filters.searched_name.length > 3){
            props.onFilterChange(filters);
        }

        if(filters.searched_name === ''){
            props.onFilterChange(filters);
        }
    }, [filters.searched_name]);

    useEffect(() => {
        if(filters.date && filters.date !== ''){
            props.onFilterChange(filters);
        }

        if(filters.date === '' && filters.searched_name !== ''){
            props.onFilterChange(filters);
        }
    }, [filters.date]);

    const setFilterDate = (event) => {
        setFilters({ ...filters, ...{ date: event.target.value } });
    }

    const setFilterUser = (event) => {
        setFilters({ ...filters, ...{ searched_name: event.target.value } });
    }

    const clearDateFilter = () => {
        setFilters({ ...filters, ...{ date: '' } });
    }

    const clearNameFilter = () => {
        setFilters({ ...filters, ...{ searched_name: '' } });
    }

    return (
        <>
            <Grid 
                item
                xs={12}
                lg={4}
            >
                <FormControl 
                    fullWidth
                >
                    <TextField 
                        variant='outlined'
                        placeholder='Search influencers added by specific person'
                        value={ filters.searched_name || '' }
                        onChange={ setFilterUser }
                        type="text"
                    />
                    {
                        filters.searched_name &&
                        <>
                            <Box
                                paddingTop={ 1 }
                                sx={{ 
                                    cursor: 'pointer'
                                }}
                                marginLeft={ 'auto' }
                                onClick={ clearNameFilter }
                            >
                                <Typography
                                    fontSize={ 'small' }
                                    bgcolor={ '#ffd9e4' }
                                    width={ 'fit-content' }
                                    paddingX={ 1 }
                                    paddingY={ 0.2 }
                                    borderRadius={ 1 }
                                    display={ 'flex' }
                                    alignItems={ 'center' }
                                    gap={ 1 }
                                >
                                    clear name filter
                                    <Icon>
                                        close
                                    </Icon>
                                </Typography>
                            </Box>
                        </>
                    }
                </FormControl>
            </Grid>

            <Grid 
                item
                xs={12}
                lg={2}
            >
                <FormControl 
                    fullWidth
                >
                    <TextField 
                        type='date'
                        value={ filters.date || '' }
                        onChange={setFilterDate}
                    />
                    {
                        filters.date &&
                        <>
                            <Box
                                paddingTop={ 1 }
                                sx={{ 
                                    cursor: 'pointer'
                                }}
                                marginLeft={ 'auto' }
                                onClick={ clearDateFilter }
                            >
                                <Typography
                                    fontSize={ 'small' }
                                    bgcolor={ '#ffd9e4' }
                                    width={ 'fit-content' }
                                    paddingX={ 1 }
                                    paddingY={ 0.2 }
                                    borderRadius={ 1 }
                                    display={ 'flex' }
                                    alignItems={ 'center' }
                                    gap={ 1 }
                                >
                                    clear date filter
                                    <Icon>
                                        close
                                    </Icon>
                                </Typography>
                            </Box>
                        </>
                    }
                </FormControl>
            </Grid>
        </>
    )
}

export default AddedCreatorsFilters;