import { Box, Card, CardContent, Avatar, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';

function AddedCreatorsCard(props){
    const [count, setCount] = useState(0);
    
    useEffect(() => {
        if(props.details.value){
            setCount(props.details.value);
        }
    }, [props]);

    return (
        <>
            <Card
                sx={{ maxWidth: '100%' }}
            >
                <CardContent
                    sx={{ 
                        paddingTop: 2
                    }}
                >
                    <Stack
                        direction='row'
                        spacing={2}
                    >
                        <Box>
                            <Avatar
                                sx={{ bgcolor: '#380561' }}
                            >
                                { props.details.icon }
                            </Avatar>
                        </Box>
                        <Box>
                            <Typography
                                fontSize={'1rem'}
                                fontWeight={'600'}
                            >
                                { props.details.title }
                            </Typography>
                            <Typography
                                fontSize={'1rem'}
                                fontWeight={'600'}
                                color={'primary'}
                            >
                                { count }
                            </Typography>
                        </Box>
                    </Stack>
                </CardContent>    
            </Card>
        </>
    )
}

export default AddedCreatorsCard;