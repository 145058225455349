import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Paper
} from "@mui/material";
import Category from "./components/categories";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import adminEnpoints from "services/adminservices";
import Swal from "sweetalert2";

function UpdatedCategoriesLayout() {
  const customStyle = { fontSize: "13px" };
  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
  });

  const handleCategoryChange = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      ["name"]: e,
    }));
  };

  const handleNewCategoryChange = (value) => {
    setNewCategory(value);
    if (value) {
      Swal.fire({
        title: "Are you sure to add new category?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Add"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await adminEnpoints.addNewCategory({ name: value });
          if (response['success']) {
            Swal.fire({
              title: "Added!",
              text: `${response['message']}`,
              icon: "success"
            });
            setCategory([...category, { name: value }]);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        }
      });
    }
  };

  const getInit = async () => {
    const responseCategory = await adminEnpoints.getCategoriesforUpdate();
    setCategory(responseCategory);
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Paper sx={{ padding: 5 }}>
          <Category
            handleChange={handleCategoryChange}
            userData={userData}
            loading={loading}
            category={category}
            customStyle={customStyle}
            handleNewCategoryChange={handleNewCategoryChange}
          />
        </Paper>
      </DashboardLayout>
    </>
  );
}

export default UpdatedCategoriesLayout;
