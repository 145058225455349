import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Typography, Checkbox, Stack, TextField, FormControl, FormControlLabel, FormGroup, RadioGroup, Radio, InputAdornment, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import validator from 'validator';
import adminEnpoints from 'services/adminservices';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const InfluencerDetailsForm = React.forwardRef((props, ref) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const font_size = "small";
    const phoneNumberInput = React.useRef(null);

    const [managerSection, setManagerSection] = useState(false);
    const [influencerDetails, setInfluencerDetails] = useState({});
    const [phoneNumberCounter, setPhoneNumberCounter] = useState(1);
    const [influencer_comments, setInfluencerComments] = useState("");
    const [categories, setCategories] = useState([]);
    const [cookies] = useCookies(["adminuser"]);
    
    useEffect(() => {
    
    }, [props]);

    useEffect(() => {
        async function getDetails(){
            let postdata = {
                username: props.details
            }
            let response = await adminEnpoints.fetchInfluencerDetails(postdata);
            if(response['data']){
                setInfluencerDetails({ ...influencerDetails, ...response['data'] });
            }

            response = await adminEnpoints.getCategories();
            if(response['success'] === true){
                setCategories(response['categories'])
            }
        }

        getDetails();
    }, []);

    const toggleManagerDetailSection = (event) => {
        setManagerSection(event.target.checked);
    }

    const handleMobileNumberInput = (event) => {
        let keyCode = event.keyCode || event.which;
        let prevent_input = true;
        
        if((keyCode >= 48 && keyCode <= 57) || keyCode === 43){
            prevent_input = false;
        }
        
        if(prevent_input){
            event.returnValue = false;
            if(event.preventDefault){
                event.preventDefault();
            }
        }
    };

    const handleNameChange = (event) => {
        setInfluencerDetails({ ...influencerDetails, ...{ influencer_name: event.target.value } });
    };

    const handleEmailChange = (event) => {
        setInfluencerDetails({ ...influencerDetails, ...{ influencer_email: event.target.value } });
    };

    const handlePhoneNumberChange = (event) => {
        let key = `influencer_phone_${ phoneNumberCounter }`;
        setInfluencerDetails({ ...influencerDetails, ...{ [key] : event.target.value } });
    };

    const addPhoneNumber = (event) => {
        if(managerSection && influencerDetails['manager_name']){
            let phone_number = influencerDetails[`influencer_phone_${ phoneNumberCounter }`];
            let manager_name = influencerDetails['manager_name'];
            let agency_name = influencerDetails['agency_name'];

            let number_obj = {
                agency: agency_name,
                manager_name: manager_name,
                manager_number: phone_number,
                isActive: true
            };

            setInfluencerDetails({ ...influencerDetails, ...{ [`influencer_phone_${ phoneNumberCounter }`]: number_obj } });
            
            let updated_counter = phoneNumberCounter + 1;
            setPhoneNumberCounter(updated_counter);
            
            setManagerSection(false);

            phoneNumberInput.current.value = "";
        }
        else{
            let updated_counter = phoneNumberCounter + 1;
            setPhoneNumberCounter(updated_counter);
            phoneNumberInput.current.value = "";
        }
    };

    const handleManagerName = (event) => {
        setInfluencerDetails({ ...influencerDetails, ...{ manager_name: event.target.value } });
    }

    const handleAgencyName = (event) => {
        setInfluencerDetails({ ...influencerDetails, ...{ agency_name: event.target.value } });
    }

    const handleGender = (event) => {
        setInfluencerDetails({ ...influencerDetails, ...{ gender: event.target.value } });
    }

    const setInfluencerCategory = (values) => {
        setInfluencerDetails({ 
            ...influencerDetails, 
            ...{ 
                category: values.map(category => category.value),
                categoryName: values
            }
        })
    }

    // const handleComment = (event) => {
    //     setInfluencerComments(event.target.value);
    // }

    const saveDetails = async () => {
        let number_key_exists = false;
        let keys = Object.keys(influencerDetails);
        
        if(keys.indexOf('influencer_phone_') > -1){
           number_key_exists = true;
        }
        
        // let name_condn = influencerDetails['influencer_name'] || influencerDetails['name'],
        // email_condn = influencerDetails['influencer_email'] || influencerDetails['haveEmail'],
        // phone_condn = number_key_exists || influencerDetails['havePhone'];
        
        // if(!name_condn || (!email_condn && !phone_condn)){
        //     alert('Please provide influencer name, email or phone number');
        //     return;
        // }

        // if((!influencerDetails['influencer_email'] || !number_key_exists)){
        //     alert("Please provide comment about the contact details being provided");
        //     return;
        // }

        let phone_numbers = "";
        let number_obj = {};
        for(let i = 0; i < keys.length; i++){
            if(keys[i].match("influencer_phone_")){
                if(typeof(influencerDetails[keys[i]]) == "string"){
                    if(phone_numbers){
                        phone_numbers += ',';
                    }
                    phone_numbers += influencerDetails[keys[i]];
                }

                if(typeof(influencerDetails[keys[i]]) == "object"){
                    number_obj = influencerDetails[keys[i]];
                }
            }
        }
        
        if(influencerDetails['manager_name'] && Object.keys(number_obj).length === 0){
            number_obj = {
                agency: influencerDetails['agency_name'],
                manager_name: influencerDetails['manager_name'],
                manager_number: influencerDetails[`influencer_phone_${ phoneNumberCounter }`],
                isActive: true
            };
        }

        let postdata = {
            username: props.details,
            name: influencerDetails['influencer_name'] || influencerDetails['name'],
            gender: influencerDetails['gender'],
            email: influencerDetails['influencer_email'] || '',
            manager: number_obj || {},
            phone: phone_numbers || '',
            comments: `${ props.details } details updated at ${ Date.now() }`,
            user_email: cookies.adminuser.email,
            have_email: influencerDetails['haveEmail'],
            have_number: influencerDetails['havePhone'],
            category: influencerDetails['category'] ? influencerDetails['category'].join(',') : ''
        }
        
        const response = await adminEnpoints.saveRequestedInfluencerDetails(postdata);
        if(response['success'] === true){
            Swal.fire({
                title: 'Update Creator Details',
                text: response['message'],
                icon: 'success'
            })
        }
        else{
            Swal.fire({
                title: 'Update Creator Details',
                text: response['message'],
                icon: 'error'
            })
        }
        window.location.reload();
    }

    return (
        <Box
            sx={ style }
            ref={ ref }
            { ...props }
        >
            <Typography
                fontSize={ font_size }
                marginBottom={2}
            >
                Enter following details for the Influencer
            </Typography>

            <Stack spacing={2}>
                <FormControl fullWidth>
                    <TextField 
                        variant='outlined'
                        value={ props.details }
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        variant='outlined'
                        placeholder='Influencer Name'
                        required
                        onChange={handleNameChange}
                        value={ influencerDetails['influencer_name'] || influencerDetails['name'] || '' }
                        disabled={ influencerDetails['name'] ? true : false }
                        inputProps={{
                            style: {
                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)'
                            }
                        }}
                    />
                </FormControl>
                
                <FormControl fullWidth>
                    <TextField 
                        variant='outlined'
                        placeholder='Enter phone number (Add Country Code 91)'
                        inputProps={{
                            // maxLength: 12,
                            style: { letterSpacing: '0.25px', WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)' }
                        }}
                        required
                        onKeyPress={handleMobileNumberInput}
                        onChange={handlePhoneNumberChange}
                        InputProps={{
                            endAdornment: !influencerDetails.havePhone && <InputAdornment position='end'>
                                <IconButton 
                                    fontSize={ font_size }
                                    color="primary"
                                    onClick={addPhoneNumber}
                                >
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                            </InputAdornment>
                        }}
                        inputRef={ phoneNumberInput }
                        value={ influencerDetails[`influencer_phone_${ phoneNumberCounter }`] || (influencerDetails.havePhone && '12*** ***45') || '' }
                        disabled={ influencerDetails.havePhone }
                    />
                </FormControl>
                
                {
                    !influencerDetails.havePhone &&
                    <FormGroup>
                        <FormControlLabel 
                            control={ <Checkbox/> }
                            label="This is a manager's number"
                            onChange={ toggleManagerDetailSection }
                            checked={ managerSection }
                        />
                    </FormGroup>
                }
                
                {
                    managerSection &&
                    <FormControl fullWidth>
                        <TextField
                            variant='outlined'
                            placeholder='Enter Manager Name'
                            onChange={ handleManagerName }
                        />
                    </FormControl>
                }

                {
                    managerSection && 
                    <FormControl fullWidth>
                        <TextField
                            variant='outlined'
                            placeholder='Enter Agency Name'
                            onChange={ handleAgencyName }
                        />
                    </FormControl>
                }

                <FormControl fullWidth>
                    <RadioGroup
                        row
                        onChange={handleGender}
                        value={influencerDetails.gender ? influencerDetails.gender : ''}
                    >
                        <FormControlLabel value={ "M" } control={ <Radio/> } label="Male"/>
                        <FormControlLabel value={ "F" } control={ <Radio/> } label="Female"/>
                        <FormControlLabel value={ "O" } control={ <Radio/> } label="Other"/>
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        variant='outlined'
                        placeholder='Enter Email Address'
                        required
                        onChange={handleEmailChange}
                        inputProps={{
                            style: {
                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)'
                            }
                        }}
                        value={ influencerDetails['influencer_email'] || (influencerDetails.haveEmail && 'abc***@gm*****.com') || '' }
                        disabled={ influencerDetails.haveEmail }
                    />
                </FormControl>

                {/* <FormControl fullWidth>
                    <TextField
                        variant='outlined'
                        InputProps={{
                            style: { minHeight: '50px' }
                        }}
                        placeholder="Enter comment about the details provided"
                        onChange={handleComment}
                    />
                </FormControl> */}

                <FormControl fullWidth>
                    <Select 
                        options={categories}
                        components={animatedComponents}
                        isMulti
                        className='basic-multi-select'
                        closeMenuOnSelect={false}
                        onChange={setInfluencerCategory}
                        value={influencerDetails['categoryName'] || []}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <Button 
                        variant='contained'
                        color='primary'
                        sx={{ color: '#FFFFFF' }}
                        type="button"
                        onClick={saveDetails}
                    >
                        Save Details
                    </Button>
                </FormControl>
            </Stack>
        </Box>
    )
});

export default InfluencerDetailsForm;