import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import SalesRequests from "./SalesRequests";


function QueriesLayout(){
    const [cookies] = useCookies(["adminuser"]);

    return (
        cookies.adminuser && cookies.adminuser.email !== "undefined" ?
        <DashboardLayout>
            <DashboardNavbar/>
            <SalesRequests/>
        </DashboardLayout>
        :
        <Navigate to={ "authentication/sign-in" }/>            
    )
}

export default QueriesLayout;