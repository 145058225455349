import * as React from 'react';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { Paper, Grid, TextField } from '@mui/material';
import validator from 'validator';
import adminEnpoints from 'services/adminservices';
import ShowFilteredList from './components/FilteredResults';

function Influencers(){
    const [cookies] = useCookies(["adminuser"]);
    const [searchedCreator, setSearchedCreator] = useState("");
    const [foundCreators, setFoundCreators] = useState([]);

    useEffect(() => {
        searchInfluencer();
    }, [searchedCreator]);

    const handleSearchInputChange = (event) => {
        setSearchedCreator(event.target.value);
    };

    const searchInfluencer = async () => {
        if(searchedCreator.length > 2){
            let isLink = validator.isURL(searchedCreator);
            let username = "";
            let type = "text";
            if(isLink){
                try{
                    let url = new URL(searchedCreator);
                    if(url.host.indexOf("instagram") === -1){
                        throw new Error("Not a proper instagram url");
                    }
                    username = url.pathname.replaceAll("/", "");
                    type = "url";
                }
                catch(error){
                    alert(error.message);
                    return;
                }
            }
            else{
                username = searchedCreator;
            }

            let postdata = {
                searched: username,
                searchtype: type
            };
            const response = await adminEnpoints.searchInfluencer(postdata);
            if(response['success'] === true){
                setFoundCreators(response['data']);
            }
            else{
                alert(response['message']);
            }
        }
        else{
            setFoundCreators([]);
        }
    };

    return (
        cookies.adminuser && cookies.adminuser.email !== "undefined" ? (
            <DashboardLayout>
                <DashboardNavbar />
                <Paper
                    sx={{ padding: 3 }}
                >
                    <Grid item xs={12} md={12} marginX={ "auto" }>
                        <TextField 
                            variant="outlined"
                            label='Type influencer name or paste instagram profile url'
                            fullWidth
                            onChange={ handleSearchInputChange }
                            onDrop={ handleSearchInputChange }
                        />
                        {
                            foundCreators.length > 0 ? 
                            <ShowFilteredList users={foundCreators}/>
                            :
                            <></>
                        }
                    </Grid>
                </Paper>
            </DashboardLayout>
        )
        :
        <Navigate to={ "authentication/sign-in" }/>
    );
}

export default Influencers;