import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import adminEndpoints from "services/adminservices";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  Box,
  Pagination,
  Skeleton,
  Avatar,
  LinearProgress,
} from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Swal from "sweetalert2";

function UpdatedCreatorsLayout() {
  const [cookies] = useCookies(["adminuser"]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [exportList, setExportList] = useState([]);
  const [foundCreators, setFoundCreators] = useState([]);
  const [searchedCreator, setSearchedCreator] = useState("");
  const [isSelectAll, setSelectAll] = useState(false);
  const [countLoading, setCountLoading] = useState(false);

  const handleSearchInputChange = (event) => {
    if (event.key === "Enter") setSearchedCreator(event.target.value);
  };

  const searchInfluencer = async () => {
    setFoundCreators([]);
    // setCount(0);
    if (searchedCreator.length > 2) {
      setLoading(true);
      let biography = "";
      let type = "text";

      if (type === "text") biography = searchedCreator;

      let postdata = {
        searched: biography,
        searchtype: type,
        page: page,
      };

      const response = await adminEndpoints.searchInfluencerInBIO(postdata);

      if (response["success"]) {
        setFoundCreators(response["data"]);
      } else {
        alert(response["message"]);
      }
      setLoading(false);
    } else {
      setFoundCreators([]);
    }
  };

  const searchInfluencerCount = async () => {
    setCount(0);
    if (searchedCreator.length > 2) {
      setCountLoading(true);
      let biography = "";
      let type = "text";

      if (type === "text") biography = searchedCreator;

      let postdata = {
        searched: biography,
        searchtype: type,
        page: page,
      };

      const responseCount = await adminEndpoints.searchInfluencerInBIOCount(postdata);
      setCount(responseCount["data"]);
      setCountLoading(false);
    }
  };

  function searchAndMark(inputString) {
    const searchString = searchedCreator;

    let searched = searchString.trim();
    if (searched !== "") {
      let re = new RegExp(searched, "gi"); // search for all instances
      let newText = inputString.replace(re, `<mark>${searched}</mark>`);
      return newText;
    }
  }

  const handleCheckboxClick = async (event, id) => {
    if (id === "selectAll") {
      if (isSelectAll === false) setExportList([]);
      setSelectAll(!isSelectAll);
      const allCheckboxIds = document.getElementsByClassName("input-checkbox");

      for (let i = 0; i < allCheckboxIds.length; i++) {
        allCheckboxIds[i].checked
          ? (allCheckboxIds[i].checked = false)
          : (allCheckboxIds[i].checked = true);
      }
    } else {
      if (event.target.checked && !exportList.includes(id)) {
        setExportList([...exportList, id]);
      } else if (!event.target.checked) {
        const newList = exportList.filter((item) => item !== id);
        setExportList(newList);
      }
    }
  };

  const handleButtonClick = async (typeOfUpdate) => {
    let response = {};

    if (typeOfUpdate === "manager") {
      const { value: formValues } = await Swal.fire({
        title: `Enter ${typeOfUpdate}`,
        html: `
                    <input id="input1" class="swal2-input" placeholder="Agency">
                    <input id="input2" class="swal2-input" placeholder="Manager Name">
                    <input id="input3" class="swal2-input" placeholder="Manager Number">
                `,
        showCancelButton: true,
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById("input1").value,
            document.getElementById("input2").value,
            document.getElementById("input3").value,
          ];
        },
      });

      if (formValues) {
        Swal.fire({
          title: `Updating....`,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
        response = await adminEndpoints.updateRequests({
          lists: exportList,
          name: typeOfUpdate,
          value: formValues,
          selectAll: isSelectAll,
          search: searchedCreator,
        });
      }
    }
    else if (typeOfUpdate === "state") {
      const { value: formValues } = await Swal.fire({
        title: `Enter ${typeOfUpdate}`,
        html: `
                    <input id="input1" class="swal2-input" placeholder="State">
                    <input id="input2" class="swal2-input" placeholder="City">
                `,
        showCancelButton: true,
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById("input1").value,
            document.getElementById("input2").value,
          ];
        },
      });

      if (formValues) {
        Swal.fire({
          title: `Updating....`,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
        response = await adminEndpoints.updateRequests({
          lists: exportList,
          name: typeOfUpdate,
          value: formValues,
          selectAll: isSelectAll,
          search: searchedCreator,
        });
      }
    }
    else {
      const { value: value } = await Swal.fire({
        title: `Enter ${typeOfUpdate}`,
        input: "text",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        },
      });
      if (value) {
        Swal.fire({
          title: `Updating....`,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });

        response = await adminEndpoints.updateRequests({
          lists: exportList,
          name: typeOfUpdate,
          value: value,
          selectAll: isSelectAll,
          search: searchedCreator,
        });
      }
    }

    if (response["success"] && response["status"] === 200) {
      Swal.close();
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: `${response["message"]}`,
        showConfirmButton: false,
        timer: 2000,
      });
    } else if (!response["success"] && response["status"] === 200) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${response["message"]}`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    searchInfluencer();
  }, [searchedCreator, page]);

  useEffect(() => {
    searchInfluencerCount();
  }, [searchedCreator]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Paper sx={{ padding: 5 }}>
          <TextField
            variant="outlined"
            label="Type State, DOB & Manager keywords in BIOS..."
            fullWidth
            onKeyPress={handleSearchInputChange}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <h1>{countLoading ? <Skeleton animation="wave" width={30} /> : count}</h1>

            <Box marginTop={2}>
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={() => handleButtonClick("birth year")}
                disabled={exportList.length === 0 && !isSelectAll}
              >
                Birth Year
              </Button>{" "}
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={() => handleButtonClick("state")}
                disabled={exportList.length === 0 && !isSelectAll}
              >
                State & City
              </Button>{" "}
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={() => handleButtonClick("manager")}
                disabled={exportList.length === 0 && !isSelectAll}
              >
                Manager
              </Button>
            </Box>
          </Box>
          {foundCreators.length > 0 ? (
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <input
                      type="checkbox"
                      id={"selectAll"}
                      title="Select All"
                      style={{ width: "18px", height: "18px", cursor: "pointer" }}
                      checked={isSelectAll}
                      onClick={(event) => handleCheckboxClick(event, "selectAll")}
                    />
                  </TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Biography</TableCell>
                </TableRow>
                {foundCreators.map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <input
                        type="checkbox"
                        id={row._id}
                        className="input-checkbox"
                        title="Select"
                        checked={isSelectAll ? isSelectAll : exportList.find((e) => e === row._id)}
                        style={{ width: "18px", height: "18px", cursor: "pointer" }}
                        onClick={(event) => handleCheckboxClick(event, row._id)}
                      />
                    </TableCell>
                    <TableCell>{row.username}</TableCell>
                    <TableCell>{row.dob}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>
                      <div
                        dangerouslySetInnerHTML={{ __html: searchAndMark(row.biography) }} className="text"
                      ></div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <center>
              {loading && (
                <>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </>
              )}
            </center>
          )}
        </Paper>
      </DashboardLayout>
      {count > 0 && (
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#F0F2F5",
            padding: "15px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Pagination
            page={page}
            color="secondary"
            variant="outlined"
            onChange={handlePageChange}
            count={Math.ceil(count / 100)}
            sx={{ paddingRight: "50px" }}
          />
        </Box>
      )}
    </>
  );
}

export default UpdatedCreatorsLayout;
