import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import AddedCreatorsBody from './components/main';

function AddedCreatorsLayout(){
    const [cookies] = useCookies(["adminuser"]);

    return (
        cookies.adminuser && cookies.adminuser.email !== "undefined" ?
        <DashboardLayout>
            <DashboardNavbar/>
            <AddedCreatorsBody/>
        </DashboardLayout>
        :
        <Navigate to={ "authentication/sign-in" }/>            
    )
}

export default AddedCreatorsLayout;