import React from "react";
import Select from "react-select/creatable";
import { useEffect } from "react";
import { useState } from "react";
import makeAnimated from "react-select/animated";

const makeAnimatedComp = makeAnimated();

const Category = ({
    loading,
    customStyle,
    userData,
    category,
    handleChange,
    handleNewCategoryChange,
}) => {
    const [options, setOptions] = useState([{ value: "", label: "" }]);

    const handleInputChange = (inputValue, actionMeta) => {
        handleNewCategoryChange(inputValue);
        if (actionMeta.action === "menu-close") {
        }
    };

    useEffect(() => {
        let opt = [];
        category.map((c) => {
            opt.push({ value: c.name, label: c.name });
        });
        setOptions(opt);
    }, [category]);

    return (
        <div className="col" style={customStyle}>
            <Select
                isMulti={false}
                options={options}
                isClearable={true}
                isSearchable={true}
                isLoading={loading}
                className="scroll mb-2"
                placeholder="Category"
                onChange={handleChange}
                components={makeAnimatedComp}
                // value={userData.name}
                onCreateOption={handleInputChange}
            />
        </div>
    );
};

export default Category;
