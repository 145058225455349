/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { 
	Typography, 
	Stack, 
	Pagination, 
	Paper, 
	Tabs, 
	Tab, 
	TableHead, 
	TableBody, 
	Table, 
	TableRow, 
	TableCell, 
	Modal, 
	Tooltip, 
	IconButton, 
	Select, 
	MenuItem, 
	TextField, 
	FormControl, 
	InputLabel, 
	CircularProgress,
	InputAdornment,
	Collapse
} from "@mui/material";

import UserDetails from "./components/UserDetails";
import CreatorsUsers from "./components/CreatorsUsersView";
import adminEnpoints from "services/adminservices";
import InfoIcon from '@mui/icons-material/Info';
import jwt_decode from 'jwt-decode';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import InstagramIcon from '@mui/icons-material/Instagram';
import InfluencerDetailsForm from "./components/InfluencerDetailsForm";
import RequestedInfluencerDM from "./components/RequestedInfluencerDM";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const TableCellStyles = {
	fontSize: 'small'
};

function Dashboard() {
  	// const { sales, tasks } = reportsLineChartData;
  	const [cookies] = useCookies(["adminuser", "authentication"]);
  	const [usersDetails, setUsersDetails] = useState({});
	const [agenciesDetails, setAgenciesDetails] = useState({});
	const [requestedInfluencers, setRequestedInfluencers] = useState({});
	const [viewedInfluencers, setViewedInfluencers] = useState({});
	const [pageManagement, setPageManagement] = useState({
		'requested': { 'pagecount': 0, 'currentpage': 0 },
		'viewed': { 'pagecount': 0, 'currentpage': 0 }
	});
	const [datesRange, setDatesRange] = useState([]);
	const [requestedTab, setRequestedTab] = useState(0);
	const [viewedTab, setViewedTab] = useState(0);
	const [addedTab, setAddedTab] = useState(0);
	const [showDetailModal, setShowDetailModal] = useState(false);
	const [activeInfluencer, setActiveInfluencer] = useState("");
	const [detailModalProps, setDetailModalProps] = useState({});
	const [userDetailsModal, setUserDetailsModal] = useState(false);
	const [userDetailsModalProps, setUserDetailsModalProps] = useState({});
	const [addedCreators, setAddedCreators] = useState({});
	const [addedCreatorsOnDate, setAddedCreatorsOnDate] = useState([]);
	const [inactiveInfluencers, setInactiveInfluencers] = useState([]);
	const [inactiveContactsPagination, setInactiveContactsPagination] = useState({
		total_pages: 0,
		current_page: 0,
		total_inactive: 0
	});

	const [permissions, setUserPermissions] = useState([]);
	const [influencerFormModal, setInfluencerFormModal] = useState(false);
	const [requestedInfluencerDM, setRequestedInfluencerDM] = useState(false);
	const [requestedInfluencerFilter, setRequestedInfluencerFilter] = useState({});
	const [totalRequestedInfluencer, setTotalRequestedInfluencer] = useState(0);
	const [loader, setLoader] = useState({
		added_influencers_list: false
	});
	const [showInactive, setShowInactive] = useState(false);
	const [inactiveNumberUsers, setInactiveNumberUsers] = useState([]);
	const [activeRequestedTab, setActiveRequestedTab] = useState(0);
	// const itemsPerPage = 10;

	const onDetailModalClose = () => {
		setShowDetailModal(false);
		setActiveInfluencer('');
	}

	const onDetailModalOpen = () => setShowDetailModal(true);

	useEffect(() => {
		if(cookies.adminuser && cookies.authentication){
			const decoded = jwt_decode(cookies.authentication.token);
			if(decoded){
				for(let i = 0; i < decoded.permission.length; i++){
					let permission = decoded.permission[i];
					if(permission['path'] === "dashboard"){
						setUserPermissions(permission['actions']);
						Init();
						break;
					}
				}
			}
		}
	}, []);

	async function Init(){
		// dates range will be created from today till last 7 days
		// it will be timestamp
		let last7days = [];
		
		for(let i = 0; i < 7; i++){
			let current_day = new Date();
			let updated_date = current_day.setDate(current_day.getDate() - i);
			last7days.push(updated_date);
		}
		setDatesRange(last7days);
		
		let filter_options_requested = {
			action: 'all',
			date: '',
			username: ''
		};
		setRequestedInfluencerFilter(filter_options_requested);
		
		let queryData = `queryType=all&page=0&activetab=${requestedTab}`;

		const users = await adminEnpoints.fetchUsers(queryData);
		users['success'] === true ? setUsersDetails(users) : setUsersDetails({});
		
		const agencies = await adminEnpoints.fetchAgencies(queryData);
		agencies['success'] === true ? setAgenciesDetails(agencies) : setAgenciesDetails({});
		
		const requestedInfluencers = await adminEnpoints.fetchRequestedInfluencers(queryData);
		if(requestedInfluencers['success'] === true){
			setRequestedInfluencers(requestedInfluencers);
			setTotalRequestedInfluencer(requestedInfluencers['totalCount']);
		}
		
		queryData = `queryType=datebased&page=0&timestamp=${ last7days[requestedTab] }`;
		const viewedInfluencers = await adminEnpoints.fetchViewedInfluencers(queryData);
		if(viewedInfluencers['success'] === true){
			setViewedInfluencers(viewedInfluencers);
		}

		// added creators fetch
		setLoader({ ...loader, ...{ added_influencers_list: true } });

		const addedInfluencers = await adminEnpoints.creatorsAdded({});
		
		setLoader({ ...loader, ...{ added_influencers_list: false } });
		if(addedInfluencers['success'] === true){
			let added_ = { "data": addedInfluencers['data'], "added_influencers": addedInfluencers['added_influencers'] }
			setAddedCreators(added_);
			
			// let formatted_date = formatDate(last7days[0]);
			// let creators = [];
			// addedInfluencers['data'][formatted_date]['team'].map((creator, index) => {
			// 	let details = { 
			// 		id: index + 1, 
			// 		influencer_name: creator.username, 
			// 		instagram_page: creator.instagramUrl ? creator.instagramUrl : '#',
			// 		adder_name: creator.adder_details[0] ? creator.adder_details[0]['fullName'] : 'N.A',
			// 		adder_id: creator.adder_details[0] ? creator.adder_details[0]['_id'] : -1,
			// 		adder_agency: creator.adder_details[0] ? creator.adder_details[0]['agency'] : -1
			// 	}
			// 	creators.push(details);
			// });
			// setAddedCreatorsOnDate(creators);
		}

		const inactive_numbers_details = await adminEnpoints.getInactiveInfluencersContact({
			page: 0
		});

		if(inactive_numbers_details['success'] === true){
			setInactiveInfluencers(inactive_numbers_details['data']);
			let inactive_pagination = {
				total_pages: Math.ceil(inactive_numbers_details['count'] / 10),
				current_page: 1,
				total_inactive: inactive_numbers_details['count']
			};
			setInactiveContactsPagination(inactive_pagination);
		}
		
		setPageManagement(current => {
			return {
				requested: {
					pagecount: Math.ceil(requestedInfluencers['count'] / 10),
					currentpage: 1
				},
				viewed: {
					pagecount: Math.ceil(viewedInfluencers['total_request'] / 10),
					currentpage: 1
				}
			}
		});
	}

	const handleCardClick = (category) => {
		switch(category){
			case "users":
				window.open("/users", "_self");
				break;
			case "added_influencers":
				window.open("/added-influencers", "_self");
				break;
			default:
				break;
		}
	}

	const handleRequestedContactPagination = async (event, p) => {
		if(p !== pageManagement['requested']['currentpage']){
			let updated = { ...pageManagement };
			updated.requested.currentpage = p;
			// let queryData = `queryType=datebased&page=${ p-1 }&timestamp=${ datesRange[requestedTab] }`;
			if(requestedInfluencerFilter.action !== "all" || requestedInfluencerFilter.date){
				fetchRequestedInfluencerBasedOnFilter(requestedInfluencerFilter, p-1, activeRequestedTab);
			}
			else{
				let queryData = `queryType=all&page=${ p-1 }&activetab=${ activeRequestedTab }`;
				const requestedInfluencers = await adminEnpoints.fetchRequestedInfluencers(queryData);
				requestedInfluencers['success'] === true ? setRequestedInfluencers(requestedInfluencers) : setRequestedInfluencers({});
			}
			setPageManagement(updated);
		}
	}

	const handleViewedContactPagination = async (event, p) => {
		if(p !== pageManagement['viewed']['currentpage']){
			let updated = { ...pageManagement };
			updated.viewed.currentpage = p;
			let queryData = `queryType=datebased&page=${ p-1 }&timestamp=${ datesRange[viewedTab] }`;
			const viewedInfluencers = await adminEnpoints.fetchViewedInfluencers(queryData);
			viewedInfluencers['success'] === true ? setViewedInfluencers(viewedInfluencers) : setViewedInfluencers({});
			setPageManagement(updated);
		}
	}

	const handleDateChangeForRequested = async (event, newValue) => {
		// For 7 days details below code is applicable for the next 15 lines
		// setRequestedTab(newValue);
		
		// let queryData = `queryType=datebased&page=0&timestamp=${ datesRange[newValue] }`;
		// const requestedInfluencers = await adminEnpoints.fetchRequestedInfluencers(queryData);
		
		// requestedInfluencers['success'] === true ? setRequestedInfluencers(requestedInfluencers) : setRequestedInfluencers({});
		
		// setPageManagement(current => {
		// 	return {
		// 		requested: {
		// 			pagecount: Math.ceil(requestedInfluencers['total_request'] / 10),
		// 			currentpage: 1
		// 		},
		// 		viewed: current['viewed']
		// 	}
		// });

		// For filters value change
		let requested_filter_value = { ...requestedInfluencerFilter };
		requested_filter_value.date = event.target.value;
		setRequestedInfluencerFilter(requested_filter_value);
		fetchRequestedInfluencerBasedOnFilter(requested_filter_value, 0, activeRequestedTab);
	}

	const handleDateChangeForViewed = async (event, newValue) => {
		setViewedTab(newValue);

		let queryData = `queryType=datebased&page=0&timestamp=${ datesRange[newValue] }`;
		const viewedInfluencers = await adminEnpoints.fetchViewedInfluencers(queryData);
		viewedInfluencers['success'] === true ? setViewedInfluencers(viewedInfluencers) : setViewedInfluencers({});
		
		setPageManagement(current => {
			return {
				requested: current['requested'],
				viewed: {
					pagecount: Math.ceil(viewedInfluencers['total_request'] / 10),
					currentpage: 1
				}
			}
		});
	}

	const showActiveCreatorUsers = async (type, details) => {
		setActiveInfluencer(details['influencer_handle']);
		onDetailModalOpen();
		setDetailModalProps({ ...details, ...{ creator_type: type } });
	}

	const showUserDetailsModal = (updatedValue) => {
		setUserDetailsModal(true);
		setUserDetailsModalProps(updatedValue);
	}

	const dateChangeForAddedCreators = (event, newValue) => {
		setAddedTab(newValue);
		let formatted_date = formatDate(datesRange[newValue]);
		let creators = [];
		addedCreators['data'][formatted_date].map((creator, index) => {
			let details = { 
				id: index + 1, 
				influencer_name: creator.username,
				instagram_page: creator.instagramUrl ? creator.instagramUrl : '#',
				adder_name: creator.adder_details[0] ? creator.adder_details[0]['fullName'] : 'N.A',
				adder_id: creator.adder_details[0] ? creator.adder_details[0]['_id'] : -1,
				adder_agency: creator.adder_details[0] ? creator.adder_details[0]['agency'] : -1
			}
			creators.push(details);
		});
		setAddedCreatorsOnDate(creators);
	}

	const handleUsernameClick = (data) => {
		if(data.adder_id !== -1){
			let modalData = {
				showInModal: true,
				userid: data.adder_id,
				agencyid: data.adder_agency
			};
			showUserDetailsModal(modalData);
		}
	}

	const formatDate = (dateObj) => {
        dateObj = parseInt(dateObj);
        dateObj = new Date(dateObj);
        let year = dateObj.getFullYear();
        let month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
        let day = dateObj.getDate().toString().padStart(2, '0');
    
        return month + '/' + day + '/' + year;
    }

	const addInfluencerDetails = (influencer) => {
		setActiveInfluencer(influencer['influencer_handle']);
		setInfluencerFormModal(true);
	}

	const onInfluencerFormClose = () => {
		setInfluencerFormModal(false);
	}

	const showSentDMModal = (influencer) => {
		setActiveInfluencer(influencer);
		if(!influencer.dm_sent || permissions.includes("dm_sent_details")){
			setRequestedInfluencerDM(true);
		}
	}

	const onRequestedInfluencerDM = () => {
		setRequestedInfluencerDM(false);
	}

	const setRequestedInfluencerEmailSent = async (influencer) => {
		if(!influencer.email_sent){
			let update_email_sent = window.confirm(`Have you sent email using '${ cookies.adminuser.email }' email address?`);
			if(update_email_sent){
				let postdata = {
					username: influencer['influencer_handle'],
					email: cookies.adminuser.email
				};
				const response = await adminEnpoints.saveRequestedInfluencerEmailSentStatus(postdata);
				if(response['success'] === true){
					window.location.reload();
					// alert(response['message']);
				}
				else{
					alert(response['message']);
				}
			}
		}
	};

	const requestedInfluencerFilterChange = async (event) => {
		let requested_filter_value = { ...requestedInfluencerFilter };
		requested_filter_value.action = event.target.value;
		setRequestedInfluencerFilter(requested_filter_value);
		fetchRequestedInfluencerBasedOnFilter(requested_filter_value, 0, activeRequestedTab);
	}

	const handleUsernameChangeRequested = (event) => {
		let requested_filter_value = { ...requestedInfluencerFilter };
		requested_filter_value.username = event.target.value;
		setRequestedInfluencerFilter(requested_filter_value);
		if(requested_filter_value.username.length > 3){
			fetchRequestedInfluencerBasedOnFilter(requested_filter_value, 0, activeRequestedTab);
		}
		else if(requested_filter_value.username.length === 0){
			fetchRequestedInfluencerBasedOnFilter(requested_filter_value, 0, activeRequestedTab);
		}
	};

	const clearUsernameFilterRequested = () => {
		let requested_filter_value = { ...requestedInfluencerFilter };
		requested_filter_value.username = '';
		setRequestedInfluencerFilter(requested_filter_value);
		fetchRequestedInfluencerBasedOnFilter(requested_filter_value, 0, activeRequestedTab);
	};

	const fetchRequestedInfluencerBasedOnFilter = async (updatedFilter, page, tabValue) => {
		let postdata = {
			filter: updatedFilter,
			page: page,
			tab: tabValue
		};
		const response = await adminEnpoints.filterRequestedInfluencer(postdata);
		if(response['success'] === true){
			let pagination = { ...pageManagement };
			pagination.requested.pagecount = Math.ceil(response['count'] / 10);
			pagination.requested.currentpage = page + 1;
			setPageManagement(pagination);
			setRequestedInfluencers(response);
		}
	};

	const handlePageChangeInactiveNumbers = async (event, newValue) => {
		if(newValue !== inactiveContactsPagination['current_page']){
			const response = await adminEnpoints.getInactiveInfluencersContact({
				page: newValue - 1
			});
	
			if(response['success'] === true){
				let inactive_pagination = inactiveContactsPagination;
				inactive_pagination['current_page'] = newValue;
				setInactiveContactsPagination(inactive_pagination);
				setInactiveInfluencers(response['data'] || []);
			}
			else{
				alert(response['message']);
			}
		}
	}

	const handlePageChangeRequested = (event) => {
		let requested_page = event.target.value;
		requested_page = parseInt(requested_page);
		if(requested_page > 1 && requested_page < pageManagement['requested']['pagecount']){
			handleRequestedContactPagination({}, requested_page);
		}
	}

	const inactiveNumberDetails = async (uname) => {
		setInactiveNumberUsers([]);
		setActiveInfluencer(uname);
		setShowInactive(true);
		try{
			let postdata = inactiveInfluencers.filter((influencer) => {
				return influencer.username === uname
			});
	
			postdata = postdata[0];
			const response = await adminEnpoints.fetchInactiveNumberDetails(postdata);
			if(response['success']){
				setInactiveNumberUsers(response['data']);
				return;
			}
			throw new Error(response['message']);
		}
		catch(error){
			alert(error.message);
		}
	}

	const changeRequestedTab = async (ev, value) => {
		setActiveRequestedTab(value)
		setRequestedInfluencers([])
		setPageManagement({ ...pageManagement, ...{ requested: { pagecount: 0, currentpage: -1 } } })
		fetchRequestedInfluencerBasedOnFilter(requestedInfluencerFilter, 0, value);
	}

	return (
    cookies.adminuser && cookies.adminuser.email !== 'undefined' ?
    <DashboardLayout>
		<DashboardNavbar />
		<MDBox py={3}>
			<Grid container spacing={2}>
				{
					permissions.includes("total_users_card") &&
					<Grid item xs={12} md={6} lg={2.4}>
						<MDBox 
							mb={1.5}
							sx={{ cursor: "pointer" }}
							onClick={ () => { handleCardClick('users') } }
						>
							<ComplexStatisticsCard
								color="dark"
								icon="account_box"
								title="Total Users"
								count={ usersDetails['count'] ? usersDetails['count'] : 0 }
								percentage={{ color: "success", amount: "", label: "Recently Updated"  }}
							/>
						</MDBox>
					</Grid>
				}
				{
					permissions.includes("total_agencies_card") &&
					<Grid item xs={12} md={6} lg={2.4}>
						<MDBox 
							mb={1.5}
							sx={{ cursor: "pointer" }}
							onClick={ () => { handleCardClick('agencies') } }
						>
							<ComplexStatisticsCard
								color="success"
								icon="store_front"
								title="Total Agencies"
								count={ agenciesDetails['count'] ? agenciesDetails['count'] : 0 }
								percentage={{ color: "success", amount: "", label: "Recently Updated"  }}
							/>
						</MDBox>
					</Grid>
				}
				{
					permissions.includes("total_creators_card") &&
					<Grid item xs={12} md={6} lg={2.4}>
						<MDBox 
							mb={1.5}
							sx={{ cursor: "pointer" }}
							onClick={ () => { handleCardClick('added_influencers') } }
						>
							
							<ComplexStatisticsCard
								color="warning"
								icon="group_add_icon"
								title="Total Creators"
								count={ 
									addedCreators['added_influencers'] ? 
									Intl.NumberFormat("en", { notation: 'standard' }).format(addedCreators['added_influencers']) : 
									<span style={{ fontSize: '1rem' }}>
										Loading&nbsp;&nbsp;
										<CircularProgress size={ '0.9rem' }/>
									</span>
								}
								percentage={{ color: "success", amount: "", label: "Recently Updated"  }}
							/>
						</MDBox>
					</Grid>
				}
				{
					permissions.includes("requested_influencers_card") &&
					<Grid item xs={12} md={6} lg={2.4}>
						<MDBox 
							mb={1.5}
							sx={{ cursor: "pointer" }}
							onClick={ () => { handleCardClick('requested_influencer') } }
						>
							<ComplexStatisticsCard
								color="error"
								icon="pageview"
								title="Requested Creators"
								count={ totalRequestedInfluencer }
								percentage={{ color: "success", amount: "", label: "Recently Updated"  }}
							/>
						</MDBox>
					</Grid>
				}
				{
					permissions.includes("viewed_influencers_card") &&
					<Grid item xs={12} md={6} lg={2.4}>
						<MDBox 
							mb={1.5}
							sx={{ cursor: "pointer" }}
							onClick={ () => { handleCardClick('viewed_influencers') } }
						>
							<ComplexStatisticsCard
								color="info"
								icon="preview"
								title="Viewed Creators"
								count={ viewedInfluencers['count'] ? viewedInfluencers['count'] : 0 }
								percentage={{ color: "success", amount: "", label: "Recently Updated"  }}
							/>
						</MDBox>
					</Grid>
				}
			</Grid>
		</MDBox>

		<MDBox>
			<Grid container spacing={3}>
				{
					permissions.includes("all_requested_influencers") &&
					<Grid item xs={12}>
						<Paper
							sx={{ paddingY: '1.5rem', minHeight: 700, borderRadius: 4 }}
						>
							<Typography
								variant="h6"
								color="primary"
								sx={{ textTransform: 'uppercase', paddingLeft: '0.9rem' }}
							>
								all requested influencers { `(${ requestedInfluencers['count'] || '...' })` }
							</Typography>
							
							<Tabs
								sx={{ 
									backgroundColor: "#ffdbe8", 
									borderRadius: "0.5rem",
									width: '300px',
									margin: '0.5rem auto'
								}}
								size="small"
								onChange={changeRequestedTab}
								value={activeRequestedTab}
							>
								<Tab
									label={'No Details Available'}
									id={'no_details_tab'}
									value={0}
									sx={{
										fontSize: '0.85rem'
									}}
								/>

								<Tab
									label={'Partial Details Available'}
									id={'partial_details_tab'}
									value={1}
									sx={{
										fontSize: '0.85rem'
									}}
								/>
							</Tabs>
							
							{/* <Stack 
								direction={ "row" } 
								justifyContent={ "space-between" }
								marginTop={2}
								marginBottom={2}
								paddingX={1}
							>
								<Tabs 
									sx={{ flexGrow: 1, backgroundColor: "#ffdbe8", borderRadius: "0.5rem" }}
									size="small"
									value={ requestedTab }
									onChange={ handleDateChangeForRequested }
								>
									{
										datesRange.map((date, index) => (
											<Tab
												key={ `requested_tab_${ index }` }
												id={ `requested_date_${ index }` }
												value={ index }
												label={ index === 0 ? "Today" : `${ new Date(date).toLocaleString("en-US", { day: "2-digit" }) }-${ new Date(date).toLocaleString("en-US", { month: 'short' }) }` }
												sx={{ fontSize: '0.85rem' }}
											/>
										))
									}
								</Tabs>
							</Stack> */}
							{
								permissions.includes("requested_influencers_filters") &&
								<Grid 
									container
									columnSpacing={2}
									padding={2}
									spacing={2}
								>
									<Grid item xs={12} md={2}>
										<FormControl fullWidth>
											<InputLabel id="requested_influencer_action_filter">
												Select Action
											</InputLabel>
											<Select
												labelId="requested_influencer_action_filter"
												value={ requestedInfluencerFilter.action ? requestedInfluencerFilter.action : "" }
												onChange={ requestedInfluencerFilterChange }
												variant="outlined"
												label="Select Action"
												sx={{ paddingY: 1.4 }}
											>
												<MenuItem value={ "all" }>All</MenuItem>
												<MenuItem value={ "none" }>No Action</MenuItem>
												<MenuItem value={ "dm_sent" }>DM Sent</MenuItem>
												<MenuItem value={ "email_sent" }>Email Sent</MenuItem>
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={12} md={2}>
										<FormControl fullWidth>
											<TextField 
												variant='outlined'
												type={ 'date' }
												onChange={ handleDateChangeForRequested }
												value={ requestedInfluencerFilter.date ? requestedInfluencerFilter.date : "" }
											/>
										</FormControl>
									</Grid>

									<Grid
										item
										xs={12}
										md={4}
									>
										<FormControl
											fullWidth
										>
											<TextField
												variant="outlined"
												placeholder="Enter Instagram username or paste profile link"
												onChange={ handleUsernameChangeRequested }
												value={ requestedInfluencerFilter.username ? requestedInfluencerFilter.username : "" }
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton 
																size="small"
																onClick={ clearUsernameFilterRequested }
															>
																<HighlightOffIcon/>	
															</IconButton>
														</InputAdornment>
													)
												}}
											/>
										</FormControl>
									</Grid>
								</Grid>
							}
							
							<Table>
								<TableHead sx={{ display: 'table-header-group' }}>
									<TableRow>
										<TableCell sx={TableCellStyles}>Sno</TableCell>
										<TableCell sx={TableCellStyles}>Username</TableCell>
										<TableCell sx={TableCellStyles}>Date Added</TableCell>
										<TableCell sx={TableCellStyles} align="center">Last Requested</TableCell>
										<TableCell sx={TableCellStyles} align="center">Request Count</TableCell>
										<TableCell sx={TableCellStyles} align="center">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{
									requestedInfluencers["requested_influencers"] && requestedInfluencers["requested_influencers"].length > 0 ?
									requestedInfluencers["requested_influencers"].map((influencer, index) => (
										<TableRow
											key={ "requested_influencers_details" + index }
											selected={ activeInfluencer === influencer['influencer_handle'] }
										>
											<TableCell sx={TableCellStyles}>
												{ ((pageManagement.requested.currentpage - 1) * 10) + (index + 1) }
											</TableCell>

											<TableCell sx={TableCellStyles}>
												<a href={`https://www.instagram.com/${ influencer['influencer_handle'] }`} target="_blank" rel="noreferrer">
													{ influencer['influencer_handle'] }
												</a>
											</TableCell>
											
											<TableCell sx={TableCellStyles}>
												{ new Date(parseInt(influencer['_id'].substring(0, 8), 16) * 1000).toLocaleDateString() }
											</TableCell>
											
											<TableCell sx={TableCellStyles} align="center">
												{ new Date(+influencer['last_updated']).toLocaleDateString() }
											</TableCell>
											
											<TableCell sx={TableCellStyles} align="center">
												{ influencer['requesting_user'].length }
											</TableCell>
											
											<TableCell 
												align="center"
											>
												{
													permissions.includes("email_sent_status") &&
													<Tooltip
														title={
															influencer.email_sent ? 
															`${ permissions.includes("email_sent_details") ? 'Email sent by: ' + influencer.user_details[0]['fullName'] : 'Email Sent' }`: 
															`Update Sent Email`
														}
													>
														<IconButton
															size="small"
															color={ influencer.email_sent ? "primary" : "secondary" }
															onClick={() => { setRequestedInfluencerEmailSent(influencer) }}
														>
															<MarkEmailReadIcon/>
														</IconButton>
													</Tooltip>
												}
												
												{
													permissions.includes("dm_sent_status") &&
													<Tooltip
														title={ 
															influencer.dm_sent ? "DM has been sent" : "Update Sent DM"
														}
													>
														<IconButton
															size="small"
															color={ influencer.dm_sent ? "primary" : "secondary" }
															onClick={ () => { showSentDMModal(influencer) } }
														>
															<InstagramIcon/>
														</IconButton>
													</Tooltip>
												}
												
												{
													permissions.includes("add_requested_influencer_form") &&
													<Tooltip
														title="Add Influencer Details"
													>
														<IconButton 
															size="small"
															onClick={ () => { addInfluencerDetails(influencer) } }
															color="secondary"
														>
															<GroupAddIcon/>
														</IconButton>
													</Tooltip>
												}
												
												{
													permissions.includes("requested_influencer_requestee") &&
													<Tooltip
														title="Show details"
													>
														<IconButton
															size="small"
															onClick={ () => { showActiveCreatorUsers('requested', influencer) } }
															color="primary"
														>
															<InfoIcon/>
														</IconButton>
													</Tooltip>
												}
											</TableCell>
										</TableRow>
									))
									:
									<TableRow>
										<TableCell align="center" colSpan={6} sx={{ textTransform: "capitalize", fontSize: "small" }}>
										{
											!requestedInfluencers["requested_influencers"] ?
											<Stack
												justifyContent={'center'}
												alignItems={'center'}
												spacing={1}
												direction={'row'}
											>
												<Typography 
													fontSize={'0.9rem'}
													color={'primary'}
													fontWeight={600}
												>
													Loading...
												</Typography>
												<CircularProgress 
													size={'1rem'}
													color={'primary'}
												/>
											</Stack>
											:
											`No Data found for selected filter`
										}
										</TableCell>
									</TableRow>
								}
								</TableBody>
							</Table>
							{
								pageManagement['requested']['pagecount'] > 0 &&
								<Stack
									direction={ "row" }
									spacing={ 2 }
									justifyContent={ "center" }
									alignItems={ 'center' }
									marginTop={ 2 }
								>
									{
										pageManagement['requested']['pagecount'] > 0 ?
										<>
											<Pagination 
												count={ pageManagement['requested']['pagecount'] }
												page={ pageManagement['requested']['currentpage'] }
												color="primary"
												size="small"
												onChange={ handleRequestedContactPagination }
											/>
											<FormControl
												sx={{ width: 125 }}
											>
												<TextField
													variant='outlined'
													size="small"
													defaultValue={ pageManagement['requested']['currentpage'] }
													onChange={ handlePageChangeRequested }
													label={ 'Go to page number' }
												/>
											</FormControl>
										</>
										:
										<></>
									}
								</Stack>
							}
						</Paper>
					</Grid>
				}
				{
					permissions.includes("all_viewed_influencers") &&
					<Grid item xs={12} md={6}>
						<Paper
							sx={{ paddingY: '1.5rem', minHeight: 700 }}
						>
							<Typography
								variant="h6"
								color="primary"
								sx={{ textTransform: 'uppercase', paddingLeft: '0.9rem' }}
							>
								all viewed influencers { `(${ viewedInfluencers['total_request'] })` }
							</Typography>
							<Stack 
								direction={ "row" } 
								justifyContent={ "space-between" }
								marginTop={2}
								marginBottom={2}
								paddingX={1}
							>
								<Tabs 
									sx={{ flexGrow: 1, backgroundColor: "#ffdbe8", borderRadius: "0.5rem" }}
									size="small"
									value={ viewedTab }
									onChange={ handleDateChangeForViewed }
								>
									{
										datesRange.map((date, index) => (
											<Tab
												key={ `viewed_tab_${ index }` }
												id={ `viewed_date_${ index }` }
												value={ index }
												label={ index === 0 ? "Today" : `${ new Date(date).toLocaleString("en-US", { day: "2-digit" }) }-${ new Date(date).toLocaleString("en-US", { month: 'short' }) }` }
												sx={{ fontSize: '0.85rem' }}
											/>
										))
									}
								</Tabs>
							</Stack>
							{
								viewedInfluencers['data'] &&
								<Table>
									<TableHead sx={{ display: 'table-header-group' }}>
										<TableRow>
											<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }}>Sno</TableCell>
											<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }}>Username</TableCell>
											<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }} align="center">Last Viewed</TableCell>
											<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }} align="center">View Count</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											viewedInfluencers && viewedInfluencers["data"].length > 0 ?
											viewedInfluencers["data"].map((influencer, index) => (
												<TableRow
													key={ "viewed_influencers_details" + index }
													onClick={ () => { showActiveCreatorUsers('viewed', influencer) } }
													selected={ activeInfluencer === influencer['influencer_handle'] }
													sx={{
														cursor: 'pointer'
													}}
												>
													<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }}>{ ((pageManagement.viewed.currentpage - 1) * 10) + (index + 1) }</TableCell>
													<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }}>
														<a href={`https://www.instagram.com/${ influencer['influencer_handle'] }`} target="_blank" rel="noreferrer">
															{ influencer['influencer_handle'] }
														</a>
													</TableCell>
													<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }} align="center">{ new Date(+influencer['last_updated']).toLocaleDateString() }</TableCell>
													<TableCell sx={{ textTransform: "capitalize", fontSize: "small" }} align="center">{ influencer['users'].length }</TableCell>
												</TableRow>
											))
											:
											<TableRow>
												<TableCell align="center" colSpan={4} sx={{ textTransform: "capitalize", fontSize: "small" }}>
													No Influencers Viewed for selected date
												</TableCell>
											</TableRow>
										}
									</TableBody>
								</Table>
							}
							<Stack
								direction={ "row" }
								spacing={ 2 }
								justifyContent={ "center" }
								marginTop={ 2 }
							>
								{
									pageManagement['viewed']['pagecount'] > 0 ? 
									<Pagination 
										count={ pageManagement['viewed']['pagecount'] } 
										page={ pageManagement['viewed']['currentpage'] }
										color="primary"
										size="small"
										onChange={ handleViewedContactPagination }
									/>
									: <></>
								}
							</Stack>
						</Paper>
					</Grid>
				}
				{/*
					permissions.includes("all_added_influencers") &&
					<Grid item xs={12} md={6}>
						<Paper
							sx={{ paddingY: '1.5rem' }}
						>
							<Typography
								variant="h6"
								color="primary"
								sx={{ textTransform: 'uppercase', paddingLeft: '0.9rem' }}
							>
								all added influencers { `(${ addedCreatorsOnDate.length })` }
							</Typography>
							
							<Stack 
								direction={ "row" } 
								justifyContent={ "space-between" }
								marginTop={2}
								marginBottom={2}
								paddingX={1}
							>
								<Tabs 
									sx={{ flexGrow: 1, backgroundColor: "#ffdbe8", borderRadius: "0.5rem" }}
									size="small"
									value={ addedTab }
									onChange={ dateChangeForAddedCreators }
								>
									{
										datesRange.map((date, index) => (
											<Tab
												key={ `added_tab_${ index }` }
												id={ `added_date_${ index }` }
												value={ index }
												label={ index === 0 ? "Today" : `${ new Date(date).toLocaleString("en-US", { day: "2-digit" }) }-${ new Date(date).toLocaleString("en-US", { month: 'short' }) }` }
												sx={{ fontSize: '0.85rem' }}
											/>
										))
									}
								</Tabs>
							</Stack>
						</Paper>
						<div style={{ height: 700, background: '#ffffff' }}>
							<DataGrid
								columns={[
									{ field: 'id', headerName: 'Sno', width: 20, sortable: false },
									{ 
										field: 'influencer_name', 
										headerName: 'Creator Name',
										width: 200, 
										renderCell: (params) => <a href={`https://www.instagram.com/${ params.row.influencer_name }`} target={ "_blank" } rel='noreferrer'>{ params.row.influencer_name }</a>,
										sortable: false
									},
									{ 
										field: 'adder_name', 
										headerName: 'Added By', 
										width: 200, 
										headerAlign: 'center', 
										align: 'center',
										sortable: false,
										renderCell: (params) => <div onClick={ () => { handleUsernameClick(params.row) } }>{ params.row.adder_name }</div>
									}
								]}
								rows={ addedCreatorsOnDate }
								pageSize={50}
								rowsPerPageOptions={[50]}
								disableColumnMenu
								disableColumnFilter
								sx={{ fontSize: '0.85rem', paddingX: '0.75rem', cursor: 'pointer' }}
								loading={ loader.added_influencers_list }
							/>
						</div>
					</Grid>
				*/}
				{
					permissions.includes("inactive_phone_numbers_influencers") &&
					<Grid item xs={12} md={6}>
						<Paper
							sx={{ 
								paddingY: '1.5rem', 
								minHeight: 700,
								display: 'flex',
								flexFlow: 'column'
							}}
						>
							<Typography
								variant="h6"
								color="primary"
								sx={{ 
									textTransform: 'uppercase', 
									paddingLeft: '0.9rem',
									display: 'flex',
									alignItems: 'center'
								}}
								marginBottom={1.5}
							>
								{ `influencers inactive numbers (${ inactiveContactsPagination.total_inactive })` }
								&nbsp;
								<Tooltip
									title={ "Showing numbers of influencers which have been marked as inactive from users" }
									placement="top"
								>
									<InfoIcon/>
								</Tooltip>
							</Typography>
							<Table>
								<TableHead sx={{ display: 'table-header-group' }}>
									<TableRow>
										<TableCell sx={{ fontSize:"small" }}>Sno</TableCell>
										<TableCell sx={{ fontSize:"small" }}>Influencer</TableCell>
										<TableCell sx={{ fontSize:"small" }} align="center">Phone Number</TableCell>
										<TableCell sx={{ fontSize:"small" }} align="center">Downvotes</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										inactiveInfluencers.length > 0 ?
										inactiveInfluencers.map((influencer, index) => (
											React.Children.toArray(
												<>
													<TableRow
														sx={{ cursor: 'pointer', borderBottom: 'none' }}
														onClick={ () => inactiveNumberDetails(influencer.username) }
														selected={ influencer.username === activeInfluencer }
													>
														<TableCell 
															sx={{ 
																fontSize: 'small', 
																paddingLeft: '1.5rem' 
															}}
														>
															{ ((inactiveContactsPagination['current_page'] - 1) * 10) + (index + 1) }
														</TableCell>
														
														<TableCell sx={{ fontSize: 'small' }}>
															<a 
																href={`https://www.instagram.com/${ influencer.username || '' }`} 
																target={'_blank'}
															>
																{ influencer.name || influencer.username || 'N.A' }
															</a>
														</TableCell>
														
														<TableCell
															colSpan={2}
														>
														{
															React.Children.toArray(
																influencer.number.map((phone, phoneIndex) => (
																	<Typography
																		fontSize={ "small" }
																		color="primary"
																		display={'flex'}
																		justifyContent={'space-around'}
																	>
																		<span>{ phone.phone }</span>
																		<span>{ phone.count }</span>
																	</Typography>
																))
															)
														}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell
															colSpan={4}
															sx={{
																padding: 0
															}}
														>
															<Collapse 
																in={showInactive && activeInfluencer === influencer.username}
																timeout={"auto"}
																unmountOnExit
															>
																{
																	inactiveNumberUsers.length === 0 ?
																	<Stack
																		m={1}
																		justifyContent={'center'}
																		alignItems={'center'}
																		direction={'row'}
																		spacing={2}
																	>
																		<Typography
																			fontSize={'0.875rem'}
																		>
																			Loading...
																		</Typography>
																		<CircularProgress
																			size={'1rem'}
																		/>
																	</Stack>
																	:
																	<Stack
																		padding={2}
																	>
																		{
																			React.Children.toArray(
																				inactiveNumberUsers.map((number) => (
																					<>
																						<Stack
																							direction={'row'}
																							justifyContent={'space-around'}
																							alignItems={'flex-start'}
																						>
																							{/* <IconButton
																								size="small"
																								color="error"
																							>
																								<DeleteForeverIcon/>
																							</IconButton> */}

																							<Typography
																								fontSize={'0.875rem'}
																								color={'primary'}
																								marginTop={'5px'}
																							>
																								{ number.phone }
																							</Typography>
																							
																							<Stack
																								marginTop={'5px'}
																							>
																								{
																									React.Children.toArray(
																										number.users.map((user) => (
																											<Stack
																												direction={'row'}
																												spacing={2}
																												alignItems={'center'}
																											>
																												<Typography 
																													fontSize={'0.875rem'}
																												>
																													{ user.fullName }
																												</Typography>
																												<Typography 
																													fontSize={'0.875rem'}
																												>
																													{ user.email }
																												</Typography>
																											</Stack>
																										))
																									)
																								}
																							</Stack>
																						</Stack>
																					</>
																				))
																			)
																		}
																	</Stack>
																}
															</Collapse>	
														</TableCell>
													</TableRow>
												</>
											)
										))
										:
										<TableRow>
											<TableCell colSpan={4}>
												<Typography
													color={ "error" }
													fontSize={ "small" }
													textAlign={ "center" }
												>
													No Inactive Numbers Found
												</Typography>
											</TableCell>
										</TableRow>
									}
								</TableBody>
							</Table>
							<Stack
								direction={ "row" }
								spacing={ 2 }
								justifyContent={ "center" }
								marginTop={ 'auto' }
							>
								{
									inactiveContactsPagination['total_pages'] > 0 ? 
									<Pagination 
										count={ inactiveContactsPagination['total_pages'] } 
										page={ inactiveContactsPagination['current_page'] }
										color="primary"
										size="small"
										onChange={ handlePageChangeInactiveNumbers }
									/>
									: <></>
								}
							</Stack>
						</Paper>
					</Grid>
				}
			</Grid>
			<Modal
				open={ showDetailModal }
				onClose={ onDetailModalClose }
				children={ 
					<CreatorsUsers 
						details={ detailModalProps } 
						handleUserClick={ showUserDetailsModal }
					/>
				}
			/>

			<Modal
				open={ userDetailsModal }
				onClose={ () => setUserDetailsModal(false) }
				children={ <UserDetails details={ userDetailsModalProps } /> }
				sx={{
					overflowY: 'scroll'
				}}
			/>

			<Modal
				open={ influencerFormModal }
				onClose={ onInfluencerFormClose }
				children={ <InfluencerDetailsForm details={ activeInfluencer }/> }
			/>

			<Modal
				open={ requestedInfluencerDM }
				onClose={ onRequestedInfluencerDM }
				children={ <RequestedInfluencerDM details={ activeInfluencer }/> }
			/>
		</MDBox>
	</DashboardLayout>
	:
		<Navigate to={ "/authentication/sign-in" } />
	);
}

export default Dashboard;
