import * as React from 'react';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react'; 

function ShowFilteredList(props){
    const [matchingInfluencers, setMatchingInfluencers] = useState([]);

    useEffect(() => {
        setMatchingInfluencers(props.users);
    }, [props]);

    return (
        <Paper
            sx={{ 
                height: 350,
                paddingX: 2, 
                paddingY: 1, 
                overflowY: 'scroll' 
            }}
        >
            <Typography
                variant='p'
                color={'dark.light'}
                fontSize={'small'}
            >
                Found Influencers&nbsp;({ matchingInfluencers.length })
            </Typography>
            
            <Divider/>
            
            <List>
                {
                    matchingInfluencers.map((influencer, index) => (
                        <>
                            <ListItem 
                                key={ `found_influencer_${ index }` }
                                onClick={ () => { window.open(influencer.instagramUrl ? influencer.instagramUrl : `https://www.instagram.com/${ influencer.username }`, "_blank") } }
                                sx={{ cursor: 'pointer' }}
                            >
                                <ListItemAvatar>
                                    <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={ influencer.username }
                                    secondary={ influencer.name ? influencer.name : influencer.username }
                                    primaryTypographyProps={{
                                        color: 'primary',
                                        fontSize: '0.875rem',
                                        fontWeight: '600'
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: '0.875rem'
                                    }}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    ))
                }
            </List>
        </Paper>
    )
}

export default ShowFilteredList;