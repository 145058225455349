import axios from "axios";

function GetCookieByName(cookiename){
    let cookieArr = document.cookie.split(";");
    for(let i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(cookiename === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    
    // Return null if not found
    return {};
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL + "admin/";

axios.interceptors.request.use(
    (config) => {
        let cookie = GetCookieByName('authentication');
        if(typeof(cookie) === "string"){
            cookie = JSON.parse(cookie);
            if(cookie.token){
                config.headers['x-auth-token'] = cookie.token;
            }
        }
        
        return Promise.resolve(config);
    },
    (error) => {

    }
);

axios.interceptors.response.use(null, (error) => {
    return Promise.reject(error);
});

const http = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
}

export default http;