import { Box, Button, Radio, FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import * as React from 'react';
import adminEnpoints from 'services/adminservices';

const RequestedInfluencerDM = React.forwardRef((props, ref) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const [cookies] = useCookies(["adminuser"]);
    const [dmChannel, setdmChannel] = useState("");
    const [user, setUser] = useState({});

    const font_size = "0.9rem";

    const selectChannel = (event) => {
        setdmChannel(event.target.value);
    };

    useEffect(async () => {
        if(props.details.dm_sent){
            setdmChannel(props.details.dm_sent_channel[0]['name']);
            let queryData = `queryType=single&uid=${ props.details.dm_sent_channel[0]['updated_by'] }`;
            const response = await adminEnpoints.fetchUserDetails(queryData);
            if(response['success'] === true){
                const user = {
                    fullname: response['data']['fullName'],
                    email: response['data']['email']
                };

                setUser(user);
            }
        }
    }, []);

    const saveDMStatus = async () => {
        if(cookies.adminuser.email){
            const postdata = {
                username: props.details.influencer_handle,
                dm_channel: dmChannel,
                email: cookies.adminuser.email
            };
            
            const response = await adminEnpoints.saveRequestedInfluencerDMStatus(postdata);
            
            if(response['success'] === true){
                // alert('DM Status has been updated');
                window.location.reload();
            }
            else{
                alert(response['message']);
            }
        }
        else{
            window.location.href = "/authentication/sign-in";
        }
    };

    return (
        <Box
            sx={ style }
            ref={ ref }
            { ...props }
        >
            <Typography
                fontSize={ font_size }
                fontWeight={ 600 }
                marginBottom={ props.details.dm_sent === false ? 2 : 1 }
            >
                { 
                    !props.details.dm_sent ?
                    `Select the channel through which DM was sent`
                    :
                    `DM Sent Details`
                }
            </Typography>
            
            {
                !props.details.dm_sent ?
                <FormControl 
                    fullWidth
                    sx={{ marginBottom: 2 }}
                >
                    <RadioGroup
                        onChange={ selectChannel }
                        value={dmChannel}
                    >
                        <FormControlLabel
                            value={ "idiotic_sperm" }
                            control={ <Radio/> }
                            label="Idiotic Sperm"
                        />
                        
                        <FormControlLabel
                            value={ "filmymafia" }
                            control={ <Radio/> }
                            label="FilmyMafia"
                        />

                        <FormControlLabel
                            value={ "sarcasmicguy" }
                            control={ <Radio/> }
                            label="Sarcasmic Guy"
                        />

                        <FormControlLabel
                            value={ "mylove4bollywood" }
                            control={ <Radio/> }
                            label="My Love 4 Bollywood"
                        />

                        <FormControlLabel
                            value={ "theironicalbaba" }
                            control={ <Radio/> }
                            label="The Ironical Baba"
                        />

                        <FormControlLabel
                            value={ "webseriesmania" }
                            control={ <Radio/> }
                            label="WebSeriesMania"
                        />

                        <FormControlLabel
                            value={ "log.kya.kahege" }
                            control={ <Radio/> }
                            label="Log.kya.kahenge"
                        />

                        <FormControlLabel
                            value={ "marketing.fanatic" }
                            control={ <Radio/> }
                            label="Marketing Fanatic"
                        />
                    </RadioGroup>
                </FormControl>
                :
                <Typography
                    fontSize={ font_size }
                    color={ 'primary' }
                >
                    <span style={{ color: '#6f6e6e' }}>Channel Name:</span>&nbsp;{ dmChannel }
                    <br/>
                    <span style={{ color: '#6f6e6e' }}>Sent By:</span>&nbsp;{ user.fullname }
                </Typography>
            }
            
            { 
                !props.details.dm_sent && 
                <FormControl fullWidth>
                    <Button
                        variant='contained'
                        sx={{ color: '#FFFFFF' }}
                        type="button"
                        onClick={saveDMStatus}
                    >
                        Save DM Status
                    </Button>
                </FormControl>
            }
        </Box>
    )
});

export default RequestedInfluencerDM;