import * as React from 'react';
import { Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Paper } from '@mui/material';

function ShowData(props){
    return (
        <>
            <TableContainer
                component={Paper}
                sx={{
                    paddingY: 2,
                    paddingX: 2
                }}
            >
                <Typography
                    color={ 'primary' }
                    fontSize={ 'small' }
                    fontWeight={ '600' }
                >
                    { props.details.heading + ` (${ props.rows.length })` }
                </Typography>

                <Table>
                    <TableHead
                        sx={{ display: 'table-header-group' }}
                    >
                        <TableRow>
                            {
                                props.columns.map((column, index) => {
                                    return (
                                        <TableCell 
                                            key={`${ props.details.name }_tblhead_${ index }`}
                                            sx={{ 
                                                fontSize: 'small', 
                                                width: `${ column.width ? column.width : 'auto' }` 
                                            }}
                                        >
                                            { index > 0 ? column.title : 'Sno.' }
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.rows.length > 0 ?
                            props.rows.map((row, index) => {
                                return (
                                    <TableRow
                                        key={`${ props.details.name }_tblbody_${ index }`}
                                    >
                                        {
                                            props.columns.map((column, columnIndex) => {
                                                return (
                                                    <TableCell 
                                                        key={`${ props.details.name }_tblbody_cell_${ columnIndex }`}
                                                        sx={{
                                                            fontSize: 'small'
                                                        }}
                                                    >
                                                        {
                                                            columnIndex > 0 
                                                            ?
                                                                column.cellId === 'username' ?
                                                                <a 
                                                                    href={ row.url }
                                                                    target='_blank'
                                                                >
                                                                    { row[column.cellId] }
                                                                </a> 
                                                                :
                                                                <span>{ row[column.cellId] }</span>
                                                            :
                                                            (index + 1)
                                                        }
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                            :
                            <TableRow>
                                <TableCell
                                    colSpan={ props.columns.length }
                                    sx={{ 
                                        fontSize: 'small', 
                                        textAlign: 'center',
                                        color: 'error.main',
                                        fontWeight: '600'
                                    }}
                                >
                                    No Data Found
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ShowData;