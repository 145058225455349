import * as React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import adminEnpoints from 'services/adminservices';
import { useCookies } from 'react-cookie';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Pagination, TextField, Grid, FormControl } from '@mui/material';
import UserDetails from '../components/UserDetails';

function Users(){
    const [cookies] = useCookies(["adminuser"]);
    const [usersList, setUsersList] = useState([]);
    const [paginationDetails, setPaginationDetails] = useState({
        pagecount: 0,
        currentpage: 0
    });
    const [activeUserDetails, setActiveUserDetails] = useState({ userid: '', agencyid: '' });
    const [searchedUser, setSearchedUser] = useState("");

    useEffect(() => {
        Init();
    }, []);

    async function Init(){
        getUserDetails(1);
    }

    async function fetchSingleUserDetails(activeuserId, agencyId){
        setActiveUserDetails({
            ...{ userid: activeuserId },
            ...{ agencyid: agencyId }
        });
    }

    function changePage(event, page_no){
        setPaginationDetails({ ...paginationDetails, currentpage: page_no });
        getUserDetails(page_no);
    }

    async function getUserDetails(page_number){
        let queryData = `queryType=all&page=${ page_number - 1 }`;
        const response = await adminEnpoints.fetchUsers(queryData);
        if(response['success'] === true){
            setUsersList(response['users']);
            setPaginationDetails({ ...paginationDetails, pagecount: response['count'], currentpage: page_number });
        }
    }

    useEffect(() => {
        if(searchedUser.length > 3){
            getSearchedUsers();
        }

        if(searchedUser.length === 0){
            Init();
        }
    }, [searchedUser]);

    function handleUserSearchInput(event){
        setSearchedUser(event.target.value);
    }

    async function getSearchedUsers(){
        let postdata = {
            uname: searchedUser
        }
        const response = await adminEnpoints.fetchSearchedUsers(postdata);
        setUsersList(response['data']);
    }

    return(
        cookies.adminuser && cookies.adminuser.email !== undefined ? (
            <DashboardLayout>
                <DashboardNavbar />

                <Grid 
                    container
                    marginBottom={2}
                    padding={2}
                    component={ Paper }
                >
                    <Grid item xs={12} md={4} marginX={ "auto" }>
                        <FormControl fullWidth>
                            <TextField 
                                variant='outlined'
                                type={ "text" }
                                placeholder={ "Search for a user" }
                                onChange={ handleUserSearchInput }
                                value={ searchedUser }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                
                <TableContainer
                    component={ Paper }
                >
                    <Table>
                        <TableHead sx={{ display: 'table-header-group' }}>
                            <TableRow>
                                <TableCell sx={{ fontSize: "small" }}>Full name</TableCell>
                                <TableCell sx={{ fontSize: "small" }}>Join Date</TableCell>
                                <TableCell sx={{ fontSize: "small" }}>Email</TableCell>
                                <TableCell sx={{ fontSize: "small" }} align='center'>Phone number</TableCell>
                                <TableCell sx={{ fontSize: "small" }} align='center'>Agency name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                usersList.length > 0 &&
                                usersList.map((user, index) => (
                                    <TableRow 
                                        key={ "users_" + index }
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => { fetchSingleUserDetails(user._id, user.agency_details[0]['_id']) }}
                                        selected={ user._id === activeUserDetails.userid }
                                        hover={ true }
                                    >
                                        <TableCell
                                            sx={{ textTransform: "capitalize", fontSize: "small" }}
                                        >
                                            { user.fullName }
                                        </TableCell>

                                        <TableCell
                                            sx={{ textTransform: "capitalize", fontSize: "small" }}
                                        >
                                            { new Date(parseInt(user._id.substring(0, 8), 16) * 1000).toLocaleDateString() }
                                        </TableCell>

                                        <TableCell sx={{ fontSize: "small" }}>{ user.email }</TableCell>
                                        
                                        <TableCell 
                                            align='center' 
                                            sx={{ fontSize: "small" }}
                                        >
                                            { user.phoneNumber && `${ user.phoneNumber.substr(0, 5) } ${ user.phoneNumber.substr(5) }` }
                                        </TableCell>
                                        
                                        <TableCell align='center' sx={{ fontSize: "small" }}>{ user.agency_details.length > 0 ? user.agency_details[0]['name'] : '' }</TableCell>
                                    </TableRow>
                                ))
                            }
                            {
                                usersList.length > 0 && !searchedUser &&
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Pagination
                                            count={ Math.ceil(paginationDetails.pagecount / 10) }
                                            page={ paginationDetails.currentpage }
                                            color={ "primary" }
                                            size={ "small" }
                                            sx={{ display: "flex", justifyContent: "end" }}
                                            onChange={ changePage }
                                        />
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <UserDetails details={ activeUserDetails }/>
            </DashboardLayout>
        )
        :<Navigate to={ "/authentication/sign-in" } />
    )
}

export default Users;