import {
    Typography, 
    Table, 
    TableContainer, 
    TableRow, 
    TableCell, 
    TableHead, 
    TableBody,
    Button,
    Stack,
    Modal
} from "@mui/material";

import { useEffect, useState } from "react";
import adminEnpoints from "services/adminservices";
import QueriesComments from "./Comments";

const Row = ({ data, handleAction }) => {
    return (
        <TableRow>
            <TableCell sx={{ fontSize: 13 }}>{ new Date(parseInt(data._id.substring(0, 8), 16) * 1000).toLocaleDateString() }</TableCell>
            <TableCell sx={{ fontSize: 13 }}>{ data.fullName }</TableCell>
            <TableCell sx={{ fontSize: 13 }}>{ data.email }</TableCell>
            <TableCell sx={{ fontSize: 13 }}>{ data.phone }</TableCell>
            <TableCell sx={{ fontSize: 13 }}>{ data.idealTime }</TableCell>
            <TableCell 
                sx={{ 
                    fontSize: 13,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    color: data.status === 'closed' || data.status === 'expired' ? '#CA3433' : ''
                }}
            >
                { data.status }
            </TableCell>
            
            {
                data.status === 'started' &&
                <TableCell sx={{ fontSize: 13 }}>
                    <Button
                        variant='text'
                        sx={{ padding: 0 }}
                        onClick={() => handleAction('add_comment', data._id)}
                    >
                        Add Comment
                    </Button>
                </TableCell>
            }

            {
                (data.status === 'closed' || data.status === 'expired') &&
                <TableCell sx={{ fontSize: 13 }}>
                    <Button
                        variant='text'
                        sx={{ padding: 0 }}
                        onClick={() => handleAction('show_comment', data._id)}
                    >
                        Show Comment
                    </Button>
                </TableCell>
            }
        </TableRow>
    )
}

function SalesRequests(){
    const [queries, setQueries] = useState([]);
    const [commentsPopup, setCommentsPopup] = useState(false);
    const [activeRow, setActiveRow] = useState(null);
    const [action, setAction] = useState(null);
    const headers = ["Date", "Name", "Email", "Phone", "Ideal Time", "Status", "Comments"];

    useEffect(() => {
        async function fetchQueries(){
            const response = await adminEnpoints.getUserQueries();
            const { success, data } = response;
            if(success){
                setQueries(data);
            }
        }

        fetchQueries();
    }, []);

    useEffect(() => {
        if(activeRow !== null){
            setCommentsPopup(true);
        }
    }, [activeRow]);

    const handleAction = (action, request) => {
        setAction(action);
        setActiveRow(request);
    }

    return (
        <Stack>
            {/* table */}
            <TableContainer>
                <Typography
                    fontSize={14}
                    fontWeight={700}
                    pt={2}
                    pl={2}
                >
                    { queries.length } Queries
                </Typography>

                <Table>
                    <TableHead
                        sx={{
                            display: 'table-header-group'
                        }}
                    >
                        <TableRow>
                        {
                            headers.map((h, i) => (
                                <TableCell
                                    key={`header_lbl_${i}`}
                                    sx={{ fontSize: 14 }}
                                >
                                    {h}    
                                </TableCell>
                            ))
                        }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {
                        queries.map((q, i) => (
                            <Row
                                key={`row_${i}`}
                                data={q}
                                handleAction={handleAction}
                            />
                        ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>

            {/* modal */}
            <Modal
                open={commentsPopup}
                onBackdropClick={() => setCommentsPopup(false)}
                onClose={() => setActiveRow(null)}
            >
                <QueriesComments
                    requestId={activeRow}
                    action={action}
                />
            </Modal>
        </Stack>
    )
}

export default SalesRequests;