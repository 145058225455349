import { 
    Box, 
    Select, 
    MenuItem, 
    Stack, 
    InputLabel,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Paper
} from '@mui/material';

import * as React from 'react';
import { useEffect, useState } from 'react';
import adminEnpoints from 'services/adminservices';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const QueriesComments = React.forwardRef((props, ref) => {
    const { requestId, action } = props;
    const [status, setStatus] = useState('open');
    const [comment, setComment] = useState('');
    const [addedComments, setAddedComments] = useState([]);

    useEffect(() => {
        async function getComments(){
            const response = await adminEnpoints.viewQueryComment(`requestId=${requestId}`);
            const { success, message, comments } = response;

            if(!success){
                Swal.fire(
                    'Show Comments',
                    message,
                    'error'
                );
            }
            else{
                setAddedComments(comments);
            }
        }

        if(action === 'show_comment'){
            getComments();
        }
    }, []);

    const handleSubmit = async () => {
        try{
            let postdata = {
                'requestId': requestId,
                'comment': comment,
                'updatedStatus': status
            };
            
            const response = await adminEnpoints.addQueryComment(postdata);

            const { success, message } = response;

            Swal.fire(
                'Add Comment',
                message,
                success === true ? 'success' : 'error'
            );
        }
        catch(error){
            Swal.fire(
                'Add Comment',
                error.message,
                'error'
            );
        }
    }

    return (
        <Box
            ref={ref}
            sx={style}
            { ...props }
        >
            {
                action === 'add_comment' &&
                <Stack>
                    <Stack
                        rowGap={0.5}
                        marginBottom={2}
                    >
                        <InputLabel>Update Status</InputLabel>
                        <Select
                            value={status}
                            label={'Update Status'}
                            sx={{ padding: 0.75 }}
                            onChange={(event) => setStatus(event.target.value)}
                        >
                            <MenuItem value={'open'}>Open</MenuItem>
                            <MenuItem value={'closed'}>Closed</MenuItem>
                            <MenuItem value={'expired'}>Expired</MenuItem>
                        </Select>
                    </Stack>

                    <Stack
                        rowGap={0.5}
                        marginBottom={2}
                    >
                        <InputLabel>Note</InputLabel>
                        <TextField
                            variant='outlined'
                            placeholder='Enter Comments'
                            multiline={true}
                            rows={4}
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </Stack>

                    <Button
                        size='small'
                        variant="contained"
                        color='primary'
                        sx={{ color: '#FFFFFF' }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Stack>
            }


            {
                action === 'show_comment' && addedComments.length &&
                addedComments.map((comment, index) => (
                    <List
                        key={`comment_${index}`}
                    >
                        <ListItem
                            sx={{
                                backgroundColor: 'white',
                                boxShadow: 1,
                                padding: 1.25,
                                borderRadius: 1
                            }}
                        >
                            <ListItemText
                                primary={comment.message}
                                secondary={comment.name}
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    color: '#039F62',
                                    fontWeight: 600
                                }}
                                secondaryTypographyProps={{
                                    fontSize: 13,
                                    textTransform: 'capitalize'
                                }}
                            />

                            <ListItemText
                                primary={
                                    new Date(parseInt(comment.updateTime)).toLocaleString(
                                        'en-US',
                                        {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric'    
                                        }
                                    )
                                }
                                primaryTypographyProps={{
                                    fontSize: 13,
                                    textAlign: 'right'
                                }}
                            />
                        </ListItem>
                    </List>   
                ))
            }
        </Box>
    )
})

export default QueriesComments;