import * as React from 'react';
import { Box, IconButton, List, ListItemButton, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import adminEnpoints from 'services/adminservices';

const CreatorsUsers = React.forwardRef((props, ref) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const listItemStyle = {
        fontSize: 'small'
    }

    const [usersDetails, setUsersDetails] = useState([]);
    const [activeUser, setActiveUser] = useState({});

    useEffect(() => {
        Init();
    }, []);

    const Init = async () => {
        let users = props.details.creator_type === "requested" ? props.details.requesting_user : props.details.users;
        if(users.length > 0){
            let postdata = {
                emails: users.join(',')
            };
            const response = await adminEnpoints.fetchRequestedInfluencersUsers(postdata);
            if(response['success'] === true){
                setUsersDetails(response['names']);
            }
        }
        else{
            alert('There has been an error in showing users');
        }
    };

    const handleUserClick = (user) => {
        let updatedUser = {
            showInModal: true,
            userid: user._id,
            agencyid: user.agency
        };
        setActiveUser(user);
        props.handleUserClick(updatedUser);
    }

    return (
        <Box 
            sx={ style }
            ref={ ref }
            { ...props }
        >
            <Typography
                variant='h6'
                borderBottom={ 'dotted' }
            >
                {
                    props.details.creator_type === 'requested' ?
                    <span>
                        All users who have Requested details of&nbsp;&nbsp; 
                        <Typography variant='span' color={ 'primary' }>{ props.details.influencer_handle}</Typography>
                    </span>
                    :
                    <span>
                        All users who have Viewed details of&nbsp;&nbsp;
                        <Typography variant='span' color={ 'primary' }>{ props.details.influencer_handle}</Typography>
                    </span>
                }
            </Typography>
            <List>
                {
                    usersDetails.length > 0 ?
                        usersDetails.map((user, index) => (
                            <ListItemButton 
                                key={ `user_${index}` }
                                sx={{ paddingY: 0.75, borderBottom: 'solid 1px rgba(0, 0, 0, 0.15)' }}
                                onClick={ () => { handleUserClick(user) }}
                                selected={ activeUser.email === user.email }
                            >
                                <ListItemText
                                    primaryTypographyProps={listItemStyle}
                                >
                                    { user.fullName ? user.fullName : '' }
                                </ListItemText>
                                <Stack 
                                    justifyContent={ 'flex-end' }
                                    fontSize="small"
                                >
                                    <Tooltip 
                                        title={ user.email }
                                        placement="right"
                                    >
                                        <IconButton 
                                            size='small'
                                            color='primary'
                                            href={ `mailto:${ user.email }` }
                                        >
                                            <MailOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </ListItemButton>
                        ))
                    :
                    <></>
                }
            </List>
        </Box>
    )
})

export default CreatorsUsers;