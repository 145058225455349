import http from "./httpservices";

async function fetchUsers(queryData) {
    const { data } = await http.get("users?" + queryData);
    return data;
}

async function fetchAgencies(queryData) {
    const { data } = await http.get("agencies?" + queryData);
    return data;
}

async function fetchRequestedInfluencers(queryData) {
    const { data } = await http.get("requestedInfluencers?" + queryData);
    return data;
}

async function fetchViewedInfluencers(queryData) {
    const { data } = await http.get("viewedInfluencers?" + queryData);
    return data;
}

async function fetchUserDetails(queryData) {
    const { data } = await http.get("users?" + queryData);
    return data;
}

async function fetchAgencyDetails(queryData) {
    const { data } = await http.get("agencies?" + queryData);
    return data;
}

async function fetchRequestedInfluencersByUser(queryData) {
    const { data } = await http.get("requestedInfluencers?" + queryData);
    return data;
}

async function fetchViewedInfluencersByUser(queryData) {
    const { data } = await http.get("viewedInfluencers?" + queryData);
    return data;
}

async function fetchTransactionRequestByUser(queryData) {
    const { data } = await http.get("getTransactions?" + queryData);
    return data;
}

async function fetchRequestedInfluencersUsers(postdata) {
    const { data } = await http.post("creators/users", postdata);
    return data;
}

async function userRequestedCreatorDatewise(postdata) {
    const { data } = await http.post("users/requested_creators", postdata);
    return data;
}

async function userViewedCreatorDatewise(postdata) {
    const { data } = await http.post("users/viewed_creators", postdata);
    return data;
}

async function userAddedCreatorsDatewise(postdata) {
    const { data } = await http.post("users/added_creators", postdata);
    return data;
}

async function creatorsAdded(postdata) {
    const { data } = await http.post("creators/added", postdata);
    return data;
}

async function loginAdmin(postdata) {
    const { data } = await http.post("loginuser", postdata);
    return data;
}

async function searchInfluencer(postdata) {
    const { data } = await http.post("influencer/search", postdata);
    return data;
}

async function searchInfluencerInBIO(postdata) {
    const { data } = await http.post("influencer/search/bio", postdata);
    return data;
}

async function searchInfluencerInBIOCount(postdata) {
    const { data } = await http.post("influencer/search/bio/count", postdata);
    return data;
}

async function getAddedInfluencersByPageNumber(postdata) {
    const { data } = await http.post("user/added_influencers/", postdata);
    return data;
}

async function getInactiveInfluencersContact(postdata) {
    const { data } = await http.post("influencers/inactive_numbers", postdata);
    return data;
}

async function saveRequestedInfluencerDetails(postdata) {
    const { data } = await http.post("influencers/save_contact", postdata);
    return data;
}

async function saveRequestedInfluencerDMStatus(postdata) {
    const { data } = await http.post("requested_influencer/updatedm", postdata);
    return data;
}

async function saveRequestedInfluencerEmailSentStatus(postdata) {
    const { data } = await http.post("requested_influencer/update_email_sent", postdata);
    return data;
}

async function filterRequestedInfluencer(postdata) {
    const { data } = await http.post("requested_influencer/filter", postdata);
    return data;
}

async function fetchSearchedUsers(postdata) {
    const { data } = await http.post("users/search", postdata);
    return data;
}

async function fetchAddedInfluencersCount() {
    const { data } = await http.post("added_influencers_count");
    return data;
}

async function fetchInactiveNumberDetails(postdata) {
    const { data } = await http.post("negative-feedback/details", postdata);
    return data;
}

async function fetchInfluencerDetails(postdata) {
    const { data } = await http.post("creator/detail", postdata);
    return data;
}

async function getCategories() {
    const { data } = await http.get("influencer/categories");
    return data;
}

async function getUserQueries() {
    const { data } = await http.get("/contact/sales");
    return data;
}

async function addQueryComment(postdata) {
    const { data } = await http.post("/contact/add-comment", postdata);
    return data;
}

async function viewQueryComment(queryData) {
    const { data } = await http.get("/contact/show-comment?" + queryData);
    return data;
}

async function updateRequests(postdata) {
    const { data } = await http.post("/influencer/update", postdata);
    return data;
}

async function getCategoriesforUpdate() {
    const { data } = await http.get("/categories");
    return data;
}


async function addNewCategory(postdata) {
    const { data } = await http.post("/addcategory", postdata);
    return data;
}

const adminEnpoints = {
    fetchUsers,
    fetchAgencies,
    fetchRequestedInfluencers,
    fetchViewedInfluencers,
    fetchUserDetails,
    fetchAgencyDetails,
    fetchRequestedInfluencersByUser,
    fetchViewedInfluencersByUser,
    fetchTransactionRequestByUser,
    fetchRequestedInfluencersUsers,
    userRequestedCreatorDatewise,
    userViewedCreatorDatewise,
    userAddedCreatorsDatewise,
    creatorsAdded,
    loginAdmin,
    searchInfluencer,
    searchInfluencerInBIO,
    searchInfluencerInBIOCount,
    getAddedInfluencersByPageNumber,
    getInactiveInfluencersContact,
    saveRequestedInfluencerDetails,
    saveRequestedInfluencerDMStatus,
    saveRequestedInfluencerEmailSentStatus,
    filterRequestedInfluencer,
    fetchSearchedUsers,
    fetchAddedInfluencersCount,
    fetchInactiveNumberDetails,
    fetchInfluencerDetails,
    getUserQueries,
    addQueryComment,
    viewQueryComment,
    updateRequests,
    getCategoriesforUpdate,
    getCategories,
    addNewCategory
}

export default adminEnpoints;