import { Container, Grid, Stack, Paper } from '@mui/material';
import * as React from 'react';

import CodeOffIcon from '@mui/icons-material/CodeOff';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import AddedCreatorsCard from '../cards';
import AddedCreatorsFilters from '../filters';
import ShowData from '../tables';

import { useState, useEffect } from 'react';
import adminEnpoints from 'services/adminservices';

function AddedCreatorsBody(){
    const [addedInfluencerFilters, setAddedInfluencersFilters] = useState({});
    const [addedInfluencersByTeam, setAddedInfluencersByTeam] = useState([]);
    const [addedInfluencersByScript, setAddedInfluencersByScript] = useState([]);
    const [cards, setCards] = useState([
        {
            title: 'Influencers added by team',
            url: '',
            icon: <GroupAddIcon/>,
            value: 0
        },
        {
            title: 'Influencers added through script',
            url: '',
            icon: <CodeOffIcon/>,
            value: 0
        }
    ]);
    
    useEffect(() => {
        fetchInfluencersCount();
        Init();
    }, []);

    const Init = () => {
        let filters = {
            date: formatDate(new Date().getTime(), '-'),
            searched_name: ''
        };
        setAddedInfluencersFilters(filters);
        fetchFilteredData({});
    }

    const filtersChange = (data) => {
        let postdata = {
            filter: data
        };

        fetchFilteredData(postdata);
    }

    const fetchFilteredData = async (postdata = {}) => {
        const response = await adminEnpoints.creatorsAdded(postdata);
        if(response['success']){
            let data_search_key = '';
            if(postdata.filter){
                data_search_key = formatDate(new Date(postdata.filter.date).getTime(), '/');
            }
            else{
                data_search_key = formatDate(new Date().getTime(), '/');
            }
            
            // adding url field for influencers
            if(response['data'].hasOwnProperty(data_search_key)){
                for(let i = 0; i < response['data'][data_search_key]['team'].length; i++){
                    let influencer = response['data'][data_search_key]['team'][i];
                    if(influencer.adder_details){
                        influencer['added_by'] = influencer.adder_details[0]['fullName'];
                    }
                    influencer['url'] = `https://www.instagram.com/${ influencer['username'] }`;
                    response['data'][data_search_key]['team'][i] = influencer;
                }
    
                for(let i = 0; i < response['data'][data_search_key]['auto'].length; i++){
                    let influencer = response['data'][data_search_key]['auto'][i];
                    influencer['url'] = `https://www.instagram.com/${ influencer['username'] }`;
                    response['data'][data_search_key]['auto'][i] = influencer;
                }
    
                setAddedInfluencersByTeam(response['data'][data_search_key]['team']);
                setAddedInfluencersByScript(response['data'][data_search_key]['auto']);
            }
            else if(response['data'].hasOwnProperty('matching_influencers')){
                for(let i = 0; i < response['data']['matching_influencers'].length; i++){
                    let influencer = response['data']['matching_influencers'][i];
                    if(influencer.adder_details){
                        influencer['added_by'] = influencer.adder_details[0]['fullName'];
                    }
                    influencer['url'] = `https://www.instagram.com/${ influencer['username'] }`;
                    response['data']['matching_influencers'][i] = influencer;
                }

                setAddedInfluencersByTeam(response['data']['matching_influencers']);
            }
        }
        else{
            alert(response['message']);
        }
    }

    const fetchInfluencersCount = async () => {
        const response = await adminEnpoints.fetchAddedInfluencersCount();
        if(response['success'] === true){
            let card_data = cards;
            card_data[0]['value'] = response['data']['team_count'];
            card_data[1]['value'] = response['data']['script_count'];

            setCards(card_data);
        }
        else{
            alert(response['message']);
        }
    }

    const formatDate = (dateObj, separator) => {
        dateObj = parseInt(dateObj);
        dateObj = new Date(dateObj);
        let year = dateObj.getFullYear();
        let month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
        let day = dateObj.getDate().toString().padStart(2, '0');
        if(separator === '-'){
            return year + separator + month + separator + day;
        }
        else{
            return month + separator + day + separator + year;
        }
    }
    
    return (
        <>
            <Container
                maxWidth={'fixed'}
            >
                <Grid 
                    container
                    columnSpacing={2}
                    marginBottom={2}
                >
                    { 
                        cards.map((card, index) => {
                            return (
                                <Grid 
                                    item 
                                    xs={12} 
                                    lg={4} 
                                    key={`added_creators_card_${index}`}
                                >
                                    <AddedCreatorsCard details={card}/>
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <Grid
                    container
                    columnSpacing={2}
                    paddingLeft={2}
                    marginBottom={2}
                >
                    <Stack
                        width={ '100%' }
                        direction={ 'row' }
                        component={ Paper }
                        paddingY={ 2 }
                        paddingX={ 2 }
                        spacing={ 2 }
                    >
                        {
                            addedInfluencerFilters.date &&
                            <AddedCreatorsFilters 
                                filters={ addedInfluencerFilters } 
                                onFilterChange={ filtersChange }
                            />
                        }
                    </Stack>
                </Grid>
                
                <Grid 
                    container
                    columnSpacing={2}
                >
                    <Grid 
                        item
                        xs={12}
                        lg={6}
                    >
                        {/* Influencer added by Team */}
                        <ShowData
                            details={{
                                heading: 'Influencers Added by Team',
                                name: 'influencer_added_team'
                            }}
                            columns={[
                                {
                                    title: 'Sno'
                                },
                                {
                                    title: 'Influencer Username',
                                    cellId: 'username',
                                    width: '50%'
                                },
                                {
                                    title: 'Added By',
                                    cellId: "added_by",
                                    width: '40%'
                                }
                            ]}
                            rows={ addedInfluencersByTeam }
                        />
                    </Grid>

                    <Grid 
                        item
                        xs={12}
                        lg={6}
                    >
                        {/* Influencers added by script */}
                        <ShowData
                            details={{
                                heading: 'Influencers Added by Script',
                                name: 'influencer_added_auto'
                            }}
                            columns={[
                                {
                                    title: 'Sno'
                                },
                                {
                                    title: 'Influencer Username',
                                    cellId: 'username',
                                    width: '90%'
                                }
                            ]}
                            rows={ addedInfluencersByScript }
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default AddedCreatorsBody;