import { Typography, Paper, Pagination, Box, Grid, Stack, Divider, TableContainer, Table, TableRow, TableHead, TableBody, TableCell, TextField, FormControl, Select, MenuItem, Tabs, Tab, CircularProgress, Backdrop } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import adminEnpoints from 'services/adminservices';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const UserDetails = React.forwardRef((props, ref) => {
    const [userdetails, setUserDetails] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userTransaction, setUserTransactions] = useState({
        current_page: 1
    });
    const [fontSize, setFontSize] = useState("small");
    const [transactionFilter, setTransactionFilter] = useState({});
    const [datesRange, setDatesRange] = useState([]);
    const [requestedCreatorActiveTab, setRequestedCreatorActiveTab] = useState(0);
    const [viewedCreatorActiveTab, setViewedCreatorActiveTab] = useState(0);
    const [addedCreatorTab, setAddedCreatorTab] = useState(0);
    const [userRequestedInfluencers, setUserRequestedInfluencers] = useState({});
    const [userViewedInfluencers, setUserViewedInfluencers] = useState({});
    const [userAddedInfluencers, setUserAddedInfluencers] = useState({});
    const [loading, setLoading] = useState(false);
    const [paginationDetail, setPaginationDetails] = useState({
        "requested": {
            total_pages: 0,
            current_page: -1
        },
        "added": {
            total_pages: 0,
            current_page: -1
        },
        "viewed": {
            total_pages: 0,
            current_page: -1
        }
    });

    const results_per_page = 20;

    const style = {
        position: 'absolute',
        top: '0',
        right: '0',
        width: 450,
        bgcolor: 'background.paper',
        p: 2,
    }
    
    useEffect(() => {
        let last7days = [];
        for(let i = 0; i < 7; i++){
			let current_day = new Date();
			let updated_date = current_day.setDate(current_day.getDate() - i);
			last7days.push(updated_date);
		}
		setDatesRange(last7days);

        setUserDetails(props.details);
        let today = new Date();
        let priorDate = new Date(new Date().setDate(today.getDate() - 30));
        setTransactionFilter({
            from: `${ today.getFullYear() }-${ ("0" + (today.getMonth() + 1)).slice(-2) }-${ today.getDate() }`,
            to: `${ priorDate.getFullYear() }-${ ("0" + (priorDate.getMonth() + 1)).slice(-2) }-${ priorDate.getDate() }`,
            status: 'all'
        });
        fetchDetails();
    }, [props]);

    const fetchDetails = async () => {
        if(props.details && props.details.userid){
            setLoading(true);
            let postdata = {};
            let userid = props.details.userid,
            agencyid = props.details.agencyid;
            let queryData = "queryType=single&uid=" + userid;
            const generalInfo = await adminEnpoints.fetchUserDetails(queryData);
            let requestedInfluencersByUser = [];
            let viewedInfluencersByUser = [];
            let addedInfluencersByUser = [];
            if(props.details.showInModal){
                postdata = {
                    "email": generalInfo['data']['email']
                };

                requestedInfluencersByUser = await adminEnpoints.userRequestedCreatorDatewise(postdata);
                if(requestedInfluencersByUser['success'] === true){
                    setUserRequestedInfluencers(requestedInfluencersByUser['requested_influencers']);
                }
            }
            else{
                queryData += `&count=${ results_per_page }&page=${ 0 }`;
                requestedInfluencersByUser = await adminEnpoints.fetchRequestedInfluencersByUser(queryData);
            }

            if(props.details.showInModal){
                postdata = {
                    "uid": props.details.userid 
                };

                viewedInfluencersByUser = await adminEnpoints.userViewedCreatorDatewise(postdata);
                if(viewedInfluencersByUser['success'] === true){
                    setUserViewedInfluencers(viewedInfluencersByUser['data']);
                }
            }
            else{
                let viewedQuery = "queryType=user&uid=" + userid;
                viewedQuery += `&count=${ results_per_page }&page=${ 0 }`;
                viewedInfluencersByUser = await adminEnpoints.fetchViewedInfluencersByUser(viewedQuery);
            }

            if(props.details.showInModal){
                postdata = {
                    "uid": props.details.userid
                };

                addedInfluencersByUser = await adminEnpoints.userAddedCreatorsDatewise(postdata);
                if(addedInfluencersByUser['success'] === true){
                    setUserAddedInfluencers(addedInfluencersByUser['data']);
                }
            }
            
            let agencyDetailsQuery = "queryType=single&agency=" + agencyid;
            const agencyInfo = await adminEnpoints.fetchAgencyDetails(agencyDetailsQuery);
            let today = formatDate(new Date().getTime());
            
            let requested_influencers = [];
            let viewed_influencers = [];
            let added_influencers = [];

            // requested influencers list for both cases: modal and users page
            if(requestedInfluencersByUser['success'] === true){
                if(props.details.showInModal){
                    requested_influencers = requestedInfluencersByUser['requested_influencers'][today];
                }
                else{
                    requested_influencers = requestedInfluencersByUser['requested_influencers'];
                }
            }

            // viewed influencers list for both cases: modal and users page
            if(viewedInfluencersByUser['success'] === true){
                if(props.details.showInModal){
                    viewed_influencers = viewedInfluencersByUser['data'][today];
                }
                else{
                    viewed_influencers = viewedInfluencersByUser['data'];
                }
            }

            // for showing added influencers in modal on dashboard page
            if(addedInfluencersByUser['success'] === true){
                if(props.details.showInModal){
                    added_influencers = addedInfluencersByUser['data'][today];
                }
            }

            let details = {
                general: generalInfo['success'] === true ? generalInfo['data'] : {},
                agency: agencyInfo['success'] === true ? agencyInfo['data'] : {},
                requested: requested_influencers,
                viewed: viewed_influencers,
                added: props.details.showInModal ? added_influencers : generalInfo['data']['addedInfluencersData']
            };

            let pagination_details = {
                "requested": {
                    total_pages: Math.ceil(details.general.requestedcreators / results_per_page),
                    current_page: 1
                },
                "added": {
                    total_pages: Math.ceil(details.general.addedcreators / results_per_page),
                    current_page: 1
                },
                "viewed": {
                    total_pages: Math.ceil(details.general.viewedcreators / results_per_page),
                    current_page: 1
                }
            };

            setUserData(details);
            setPaginationDetails(pagination_details);
            fetchTransactionDetailsByPage(1, userid);
            setLoading(false);
        }
    }

    const fetchTransactionDetailsByPage = async (page, userid) => {
        let queryData = `queryType=user&uid=${ userid }&page=${page - 1}`;
        let transactionDetails = await adminEnpoints.fetchTransactionRequestByUser(queryData);
        if(transactionDetails['success']){
            setUserTransactions(
            {
                ...userTransaction, 
                ...{ 
                    transaction: transactionDetails['data'], 
                    count: transactionDetails['count'], 
                    current_page: page
                }
            });
        }
    }

    const handleTransactionPageChange = (event, page) => {
        fetchTransactionDetailsByPage(page, props.details.userid);
    }

    const handlePaymentStatusChange = (event) => {
        setTransactionFilter({...transactionFilter, ...{ status: event.target.value }});
    }

    const handleTransactionDateChange = (event) => {
        
    }

    const changeRequestedInfluencersDate = (event, newValue) => {
        setRequestedCreatorActiveTab(newValue);
        let date_key = formatDate(datesRange[newValue]);
        setUserData({ ...userData, ...{ 'requested': userRequestedInfluencers[date_key] } });
    }

    const changeViewedInfluencerDate = (event, newValue) => {
        setViewedCreatorActiveTab(newValue);
        let date_key = formatDate(datesRange[newValue]);
        setUserData({ ...userData, ...{ 'viewed': userViewedInfluencers[date_key] } });
    }

    const changeAddedInfluencerDate = (event, newValue) => {
        setAddedCreatorTab(newValue);
        let date_key = formatDate(datesRange[newValue]);
        setUserData({ ...userData, ...{ 'added': userAddedInfluencers[date_key] } });
    }

    const formatDate = (dateObj) => {
        dateObj = parseInt(dateObj);
        dateObj = new Date(dateObj);
        let year = dateObj.getFullYear();
        let month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
        let day = dateObj.getDate().toString().padStart(2, '0');
    
        return month + '/' + day + '/' + year;
    }

    const handlePageChange = async (event, newValue, mode) => {
        if(mode === "added"){
            let postdata = {
                uid: userdetails.userid,
                count: results_per_page,
                page: newValue - 1
            };
            const added_influencers = await adminEnpoints.getAddedInfluencersByPageNumber(postdata);
            if(added_influencers["success"] === true){
                setUserData({ ...userData, ...{ added: added_influencers['data'] } });
                let updated = { ...paginationDetail };
                updated.added.current_page = newValue;
                setPaginationDetails(updated);
            }
            else{
                alert(added_influencers['message']);
            }
        }
        
        if(mode === "requested"){
            let queryData = `queryType=single&uid=${ userdetails.userid }&count=${ results_per_page }&page=${ newValue - 1 }`;
            const requested_influencers = await adminEnpoints.fetchRequestedInfluencersByUser(queryData);
            if(requested_influencers['success'] === true){
                setUserData({ ...userData, ...{ requested: requested_influencers['requested_influencers'] } });
                let updated = { ...paginationDetail };
                updated.requested.current_page = newValue;
                setPaginationDetails(updated);
            }
            else{
                alert(requested_influencers['message']);
            }
        }

        if(mode === "viewed"){
            let queryData = `queryType=user&uid=${ userdetails.userid }&count=${ results_per_page }&page=${ newValue - 1 }`;
            const viewed_influencers = await adminEnpoints.fetchViewedInfluencersByUser(queryData);
            if(viewed_influencers["success"] === true){
                setUserData({ ...userData, ...{ viewed: viewed_influencers['data'] } });
                let updated = { ...paginationDetail };
                updated.viewed.current_page = newValue;
                setPaginationDetails(updated);
            }
            else{
                alert(viewed_influencers['message']);
            }
        }
    }

    return (
        <Paper
            sx={ props.details.showInModal ? style : { borderRadius: 3 }}
            ref={ ref }
            { ...props }
        >
            {
                props.details.showInModal ?
                <Backdrop 
                    sx={{ 
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        position: 'absolute',
                        width: 450,
                        height: '100vh',
                        backgroundColor: '#fff'
                    }}
                    open={loading}
                >
                    <CircularProgress color='primary'/>
                </Backdrop>
                :
                <></>
            }
            
            { 
                userData ?
                <Box
                    marginY={4}
                    padding={2}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={userdetails.showInModal ? 12 : 6}>
                            <Typography
                                color={ "primary" }
                                fontSize={ "medium" }
                                fontWeight={ "bold" }
                                marginBottom={2}
                            >
                                General Information
                            </Typography>
                            
                            <Stack spacing={1}>
                                <Stack direction={ "row" }>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                            variant={ 'h6' }
                                        >
                                            Full Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                        >
                                            { userData.general ? userData.general.fullName : '-' }
                                        </Typography>
                                    </Grid>    
                                </Stack>

                                <Stack direction={ "row" }>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                            variant={ 'h6' }
                                        >
                                            Credits
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                        >
                                            { userData.general ? userData.general.balance : 'N.A' }
                                        </Typography>
                                    </Grid>
                                </Stack>

                                {
                                    userdetails.showInModal ? <></>
                                    :
                                    <Stack direction={ "row" }>
                                        <Grid item xs={6} md={6}>
                                            <Typography
                                                fontSize={ fontSize }
                                                variant={ 'h6' }
                                            >
                                                Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Stack 
                                                direction={ "row" } 
                                                spacing={1}
                                            >
                                                <Typography
                                                    fontSize={ fontSize }
                                                >
                                                    { userData.general ? userData.general.email : '-' }
                                                </Typography>
                                                {
                                                    userData.general &&
                                                    <Typography
                                                        fontSize={ fontSize }
                                                        color={ userData.general.isEmailVerified ? 'success.main' : 'error' }
                                                        marginTop={'2px !important'}
                                                    >
                                                        { userData.general.isEmailVerified ? <CheckCircleOutlineIcon/> : <CancelIcon/> }
                                                    </Typography>
                                                }
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                }

                                {
                                    userdetails.showInModal ? <></>
                                    :
                                    <Stack direction={ "row" }>
                                        <Grid item xs={6} md={6}>
                                            <Typography
                                                fontSize={ fontSize }
                                                variant={ 'h6' }
                                            >
                                                Phone Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Stack
                                                direction={ "row" }
                                                spacing={ 1 }
                                            >
                                                <Typography
                                                    fontSize={ fontSize }
                                                >
                                                    { `+91 ${ userData.general ? userData.general.phoneNumber : '-' }` }
                                                </Typography>
                                                {
                                                    userData.general &&
                                                    <Typography
                                                        fontSize={ fontSize }
                                                        color={ userData.general.isVerified ? 'success.main' : 'error' }
                                                        marginTop={'2px !important'}
                                                    >
                                                        { userData.general.isVerified ? <CheckCircleOutlineIcon/> : <CancelIcon/> }
                                                    </Typography>
                                                }
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                }

                                <Stack direction={ "row" }>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                            variant={ 'h6' }
                                        >
                                            Total Influencers Added
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                        >
                                            { userData.general && userData.general.addedcreators }
                                        </Typography>
                                    </Grid>
                                </Stack>

                                <Stack direction={ "row" }>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                            variant={ 'h6' }
                                        >
                                            Total Influencers Viewed
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                        >
                                            { userData.general && userData.general.viewedcreators }
                                        </Typography>
                                    </Grid>
                                </Stack>
                                
                                <Stack direction={ "row" }>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                            variant={ 'h6' }
                                        >
                                            Total Influencers Requested
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography
                                            fontSize={ fontSize }
                                        >
                                            { userData.general && userData.general.requestedcreators }
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Stack>
                            <Divider/>
                        </Grid>
                        
                        {
                            userdetails.showInModal ? <></>
                            :
                            <Grid item xs={12} lg={6}>
                                <Typography
                                    color={ "primary" }
                                    fontSize={ "medium" }
                                    fontWeight={ "bold" }
                                    marginBottom={2}
                                >
                                    Agency Information
                                </Typography>
                                
                                <Stack spacing={1}>
                                    <Stack direction={ "row" }>
                                        <Grid item xs={6} md={2}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                { userData.agency ? userData.agency.companyName || userData.agency.name : '-' }
                                            </Typography>
                                        </Grid>
                                    </Stack>

                                    <Stack direction={ "row" }>
                                        <Grid item xs={6} md={2}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                Website
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                <a
                                                    href={ userData.agency && userData.agency.website }
                                                    target="_blank"
                                                    rel='noreferrer'
                                                >
                                                    { userData.agency ? userData.agency.website : '-' }
                                                </a>
                                            </Typography>
                                        </Grid>
                                    </Stack>

                                    <Stack direction={ "row" }>
                                        <Grid item xs={6} md={2}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                Credits Left
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                { userData.agency ? userData.agency.creditScore : 0 }
                                            </Typography>
                                        </Grid>
                                    </Stack>

                                    <Stack direction={ "row" }>
                                        <Grid item xs={6} md={2}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                Address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <Typography
                                                fontSize={ fontSize }
                                                color={ userData.agency && userData.agency.address ? 'dark' : 'error' }
                                            >
                                                {   
                                                    userData.agency && userData.agency.address ?
                                                    `${ userData.agency.address }, ${ userData.agency.city } ${ userData.agency.postalCode }, ${ userData.agency.state }, ${ userData.agency.country }` 
                                                    :
                                                    `Address not updated/partially updated`
                                                }
                                            </Typography>
                                        </Grid>
                                    </Stack>

                                    <Stack direction={ "row" }>
                                        <Grid item xs={6} md={2}>
                                            <Typography
                                                fontSize={ fontSize }
                                            >
                                                GST Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={8}>
                                            <Typography
                                                fontSize={ fontSize }
                                                color={ userData.agency && userData.agency.gst ? 'dark' : 'error' }
                                            >
                                                {   
                                                    userData.agency && userData.agency.gst ?
                                                    `${ userData.agency.gst }` 
                                                    :
                                                    `GST details not provided`
                                                }
                                            </Typography>
                                        </Grid>
                                    </Stack>
                                </Stack>
                                <Divider/>
                            </Grid>
                        }
                        
                        {
                            userdetails.showInModal ? <></>
                            :
                            <Grid item xs={12}>
                                <Typography
                                    color={ "primary" }
                                    fontSize={ "medium" }
                                    fontWeight={ "bold" }
                                    marginBottom={2}
                                >
                                    Transactions Details
                                </Typography>
                                <Grid item xs={12}>
                                    <Stack 
                                        direction={ "row" }
                                        spacing={2}
                                    >
                                        <FormControl sx={{ fontSize: "small" }}>
                                            <label>From:</label>
                                            <TextField
                                                variant='outlined'
                                                type={ "date" }
                                                value={ transactionFilter.from }
                                            />
                                        </FormControl>
                                        <FormControl sx={{ fontSize: "small" }}>
                                            <label>To:</label>
                                            <TextField
                                                variant='outlined'
                                                type={ "date" }
                                                value={ transactionFilter.to }
                                            />
                                        </FormControl>
                                        <Box minWidth={120}>
                                            <FormControl fullWidth sx={{ fontSize: "small" }}>
                                                <label>Payment Status:</label>
                                                <Select
                                                    id='status_select'
                                                    sx={{ paddingY: 1.2, paddingX: 1 }}
                                                    IconComponent={ () => <ArrowDropDownIcon fontSize='medium'/> }
                                                    value={ transactionFilter.status }
                                                    onChange={ handlePaymentStatusChange }
                                                >
                                                    <MenuItem value={"all"}>All</MenuItem>
                                                    <MenuItem value={"captured"}>Captured</MenuItem>
                                                    <MenuItem value={"created"}>Created</MenuItem>
                                                    <MenuItem value={"failed"}>Failed</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ display: 'table-header-group' }}>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: fontSize }}>Order ID</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Amount</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Credits</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Initiation Date</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Status</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Payment ID</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Error Details(If any)</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Previous Credits</TableCell>
                                                    <TableCell sx={{ fontSize: fontSize }} align="center">Updated Credits</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    userTransaction.transaction && userTransaction.transaction.length > 0 ?
                                                    userTransaction.transaction && userTransaction.transaction.map((transaction, index) => (
                                                        <TableRow 
                                                            key={ "transaction_" + index }
                                                            hover={ true }
                                                        >
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                            >
                                                                { transaction.razorpay_order_id }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                                align="center"
                                                            >
                                                                { JSON.parse(transaction.plan_details)['amount'] }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                                align="center"
                                                            >
                                                                { JSON.parse(transaction.plan_details)['creditsProvided'] }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                                align="center"
                                                            >
                                                                { new Date(parseInt(transaction._id.substring(0, 8), 16) * 1000).toLocaleString() }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ 
                                                                    fontSize: fontSize, 
                                                                    color: transaction.payment_status === "CAPTURED" ? "success.main" : "error.main",
                                                                    fontWeight: 600
                                                                }}
                                                                align="center"
                                                            >
                                                                { transaction.payment_status.toLowerCase() }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                                align="center"
                                                            >
                                                                { transaction.razorpay_payment_id ? transaction.razorpay_payment_id : '-' }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                                align="center"
                                                            >
                                                                { transaction.error_details.length > 0 ? transaction.error_details[0] : '-' }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                                align="center"
                                                            >
                                                                { transaction.previous_credits ? transaction.previous_credits : '-' }
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{ fontSize: fontSize }}
                                                                align="center"
                                                            >
                                                                { transaction.final_credits ? transaction.final_credits : '-' }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    :
                                                    <TableRow>
                                                        <TableCell 
                                                            sx={{ fontSize: fontSize, textAlign: "center", color: "error.main" }}
                                                            colSpan={9}
                                                        >
                                                            No Transaction Records Found
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={9}
                                                    >
                                                        {
                                                            userTransaction && userTransaction.count ?
                                                            <Pagination
                                                                count={ Math.ceil(userTransaction.count / 10) }
                                                                page={ userTransaction.current_page }
                                                                color={ "primary" }
                                                                size={ "small" }
                                                                sx={{ display: "flex", justifyContent: "end" }}
                                                                onChange={ handleTransactionPageChange }
                                                            />
                                                            :<></>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Divider/>
                            </Grid>
                        }

                        <Grid 
                            item 
                            xs={12} 
                            md={ userdetails.showInModal ? 12 : 4 }
                            sx={{ minHeight: !userdetails.showInModal && userData.added.length > 0 ?  'auto' : 'auto' }}
                        >
                            <Typography
                                color={ "primary" }
                                fontSize={ "medium" }
                                fontWeight={ "bold" }
                                marginBottom={2}
                            >
                                Added Influencers&nbsp;
                                <span>
                                {
                                    userdetails.showInModal ?
                                    `(${userData.added.length || 0})`
                                    :
                                    ''
                                }
                                </span>
                                <span>
                                {
                                    !userdetails.showInModal ?
                                    `
                                        (${ ((paginationDetail.added.current_page - 1) * results_per_page) + 1 } -
                                        ${ ((paginationDetail.added.current_page - 1) * results_per_page) + userData.added.length })
                                        of 
                                        ${ userData.general.addedcreators }
                                    `
                                    :
                                    ''
                                }
                                </span>
                            </Typography>
                            <Grid item xs={12}>
                                {
                                    userdetails.showInModal ? 
                                    <Stack direction={ "row" }>
                                        <Tabs
                                            sx={{ flexGrow: 1, backgroundColor: "#ffdbe8", borderRadius: "0.5rem" }}
                                            size="small"
                                            value={ addedCreatorTab }
                                            onChange={ changeAddedInfluencerDate }
                                        >
                                            {
                                                datesRange.map((date, index) => (
                                                    <Tab
                                                        key={ `added_tab_${ index }` }
                                                        id={ `added_date_${ index }` }
                                                        value={ index }
                                                        label={ index === 0 ? "Today" : `${ new Date(date).toLocaleString("en-US", { day: "2-digit" }) }-${ new Date(date).toLocaleString("en-US", { month: 'short' }) }` }
                                                        sx={{ fontSize: '0.85rem' }}
                                                    />
                                                ))
                                            }
                                        </Tabs>
                                    </Stack>
                                    :
                                    <></>
                                }
                                <TableContainer>
                                    <Table>
                                        <TableHead
                                            sx={{ display: "table-header-group" }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{ fontSize: fontSize }}>Instagram Handle</TableCell>
                                                <TableCell sx={{ fontSize: fontSize }} width="30%">Date Added</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                userData.added && userData.added.length > 0 ? (
                                                    userData.added.map((influencer, index) => (
                                                        <TableRow 
                                                            key={ "added_" + index }
                                                            hover={ true }
                                                        >
                                                            <TableCell sx={{ fontSize: fontSize }}>
                                                                <a
                                                                    href={ influencer.instagramUrl }
                                                                    target="_blank"
                                                                    rel='noreferrer'
                                                                >
                                                                    { influencer.username }
                                                                </a>
                                                            </TableCell>
                                                            <TableCell sx={{ fontSize: fontSize }}>
                                                                {
                                                                    userdetails.showInModal ?
                                                                    new Date(parseInt(influencer._id.substring(0, 8), 16) * 1000).toLocaleTimeString()
                                                                    :
                                                                    new Date(parseInt(influencer._id.substring(0, 8), 16) * 1000).toLocaleString()
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )
                                                :
                                                <TableRow>
                                                    <TableCell 
                                                        colSpan={2}
                                                        sx={{ fontSize: fontSize, textAlign: "center", color: "error.main" }}
                                                    >
                                                        No Influencer Records Added
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                    {
                                        !userdetails.showInModal &&
                                        <Stack 
                                            direction={ "row" }
                                            spacing={ 2 }
                                            paddingY={ 1 }
                                            justifyContent={ "flex-end" }
                                        >
                                            <Pagination 
                                                count={ paginationDetail.added.total_pages }
                                                page={ paginationDetail.added.current_page }
                                                color="primary"
                                                size='small'
                                                onChange={ (event, newValue) => { handlePageChange(event, newValue, 'added') } }
                                            />
                                        </Stack>
                                    }
                                </TableContainer>
                            </Grid>
                            <Divider/>
                        </Grid>

                        <Grid 
                            item 
                            xs={12}
                            md={ userdetails.showInModal ? 12 : 4 }
                            sx={{ minHeight: !userdetails.showInModal && userData.viewed.length > 0 ?  'auto' : 'auto' }}
                        >
                            <Typography
                                color={ "primary" }
                                fontSize={ "medium" }
                                fontWeight={ "bold" }
                                marginBottom={2}
                            >
                                Viewed Influencers&nbsp;
                                <span>
                                {
                                    userdetails.showInModal ?
                                    `(${userData.viewed.length || 0})`
                                    :
                                    ''
                                }
                                </span>
                                <span>
                                {
                                    !userdetails.showInModal ?
                                    `
                                        (${ ((paginationDetail.viewed.current_page - 1) * results_per_page) + 1 } -
                                        ${ ((paginationDetail.viewed.current_page - 1) * results_per_page) + userData.viewed.length })
                                        of 
                                        ${ userData.general.viewedcreators }
                                    `
                                    :
                                    ''
                                }
                                </span>
                            </Typography>
                            <Grid item xs={12}>
                                {
                                    userdetails.showInModal ?
                                    <Stack direction={ "row" }>
                                        <Tabs
                                            sx={{ flexGrow: 1, backgroundColor: "#ffdbe8", borderRadius: "0.5rem" }}
                                            size="small"
                                            value={ viewedCreatorActiveTab }
                                            onChange={ changeViewedInfluencerDate }
                                        >
                                            {
                                                datesRange.map((date, index) => (
                                                    <Tab
                                                        key={ `viewed_tab_${ index }` }
                                                        id={ `viewed_date_${ index }` }
                                                        value={ index }
                                                        label={ index === 0 ? "Today" : `${ new Date(date).toLocaleString("en-US", { day: "2-digit" }) }-${ new Date(date).toLocaleString("en-US", { month: 'short' }) }` }
                                                        sx={{ fontSize: '0.85rem' }}
                                                    />
                                                ))
                                            }
                                        </Tabs>
                                    </Stack>
                                    :
                                    <></>
                                }
                                <TableContainer>
                                    <Table>
                                        <TableHead
                                            sx={{ display: "table-header-group" }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{ fontSize: fontSize }}>Instagram Handle</TableCell>
                                                <TableCell sx={{ fontSize: fontSize }}>Date Viewed</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                userData.viewed && userData.viewed.length > 0 ? (
                                                    userData.viewed.map((influencer, index) => (
                                                        <TableRow 
                                                            key={ "viewed_" + index }
                                                            hover={ true }
                                                        >
                                                            <TableCell sx={{ fontSize: fontSize }}>
                                                                <a
                                                                    href={ `https://www.instagram.com/${ influencer.split(':')[0] }` }
                                                                    target="_blank"
                                                                    rel='noreferrer'
                                                                >
                                                                    { influencer.split(':')[0] }
                                                                </a>
                                                            </TableCell>
                                                            <TableCell sx={{ fontSize: fontSize }}>
                                                                { new Date(+influencer.split(':')[2]).toLocaleString() }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                )
                                                :
                                                <TableRow>
                                                    <TableCell 
                                                        colSpan={2}
                                                        sx={{ fontSize: fontSize, textAlign: "center", color: "error.main" }}
                                                    >
                                                        No Influencer Records Viewed
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                    {
                                        !userdetails.showInModal &&
                                        <Stack 
                                            direction={ "row" }
                                            spacing={ 2 }
                                            paddingY={ 1 }
                                            justifyContent={ "flex-end" }
                                        >
                                            <Pagination 
                                                count={ paginationDetail.viewed.total_pages }
                                                page={ paginationDetail.viewed.current_page }
                                                color="primary"
                                                size='small'
                                                onChange={ (event, newValue) => { handlePageChange(event, newValue, 'viewed') } }
                                            />
                                        </Stack>
                                    }
                                </TableContainer>
                            </Grid>
                            <Divider/>
                        </Grid>
                        
                        <Grid 
                            item 
                            xs={12} 
                            md={ userdetails.showInModal ? 12 : 4 }
                        >
                            <Typography
                                color={ "primary" }
                                fontSize={ "medium" }
                                fontWeight={ "bold" }
                                marginBottom={2}
                            >
                                Requested Influencers&nbsp;
                                <span>
                                {
                                    userdetails.showInModal ?
                                    `(${userData.requested.length || 0})` :
                                    ''
                                }
                                </span>
                                <span>
                                {
                                    !userdetails.showInModal && userData.requested ?
                                    `
                                        (${ ((paginationDetail.requested.current_page - 1) * results_per_page) + 1 } -
                                        ${ ((paginationDetail.requested.current_page - 1) * results_per_page) + userData.requested.length }) of 
                                        ${ userData.general.requestedcreators }
                                    `
                                    :
                                    ''
                                }
                                </span>
                            </Typography>
                            <Grid item xs={12}>
                                {
                                    userdetails.showInModal ?
                                    <Stack direction={ "row" }>
                                        <Tabs
                                            sx={{ flexGrow: 1, backgroundColor: "#ffdbe8", borderRadius: "0.5rem" }}
                                            size="small"
                                            value={ requestedCreatorActiveTab }
                                            onChange={ changeRequestedInfluencersDate }
                                        >
                                            {
                                                datesRange.map((date, index) => (
                                                    <Tab
                                                        key={ `requested_tab_${ index }` }
                                                        id={ `requested_date_${ index }` }
                                                        value={ index }
                                                        label={ index === 0 ? "Today" : `${ new Date(date).toLocaleString("en-US", { day: "2-digit" }) }-${ new Date(date).toLocaleString("en-US", { month: 'short' }) }` }
                                                        sx={{ fontSize: '0.85rem' }}
                                                    />
                                                ))
                                            }
                                        </Tabs>
                                    </Stack>
                                    :
                                    <></>
                                }
                                <TableContainer>
                                    <Table>
                                        <TableHead
                                            sx={{ display: "table-header-group" }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{ fontSize: fontSize }}>Instagram Handle</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                userData.requested && userData.requested.length > 0 ? (
                                                    userData.requested.map((influencer, index) => (
                                                        <TableRow 
                                                            key={ "requested_" + index }
                                                            hover={ true }
                                                        >
                                                            {
                                                                userdetails.showInModal ?
                                                                <TableCell sx={{ fontSize: fontSize }}>
                                                                    <a 
                                                                        href={ `https://www.instagram.com/${ influencer.influencer_handle.split(':')[0] }` }
                                                                        target="_blank"
                                                                        rel='noreferrer'
                                                                    >
                                                                        { influencer.influencer_handle.split(':')[0] }
                                                                    </a>
                                                                </TableCell>
                                                                :
                                                                <TableCell sx={{ fontSize: fontSize }}>
                                                                    <a 
                                                                        href={ `https://www.instagram.com/${ influencer.split(':')[0] }` }
                                                                        target="_blank"
                                                                        rel='noreferrer'
                                                                    >
                                                                        { influencer.split(':')[0] }
                                                                    </a>
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    ))
                                                )
                                                :
                                                <TableRow>
                                                    <TableCell 
                                                        sx={{ fontSize: fontSize, textAlign: "center", color: "error.main" }}
                                                    >
                                                        No Influencer Records Requested
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                    {
                                        !userdetails.showInModal &&
                                        <Stack 
                                            direction={ "row" }
                                            spacing={ 2 }
                                            paddingY={ 1 }
                                            justifyContent={ "flex-end" }
                                        >
                                            <Pagination 
                                                count={ paginationDetail.requested.total_pages }
                                                page={ paginationDetail.requested.current_page }
                                                color="primary"
                                                size='small'
                                                onChange={ (event, newValue) => { handlePageChange(event, newValue, 'requested') } }
                                            />
                                        </Stack>
                                    }
                                </TableContainer>
                            </Grid>
                            <Divider/>
                        </Grid>
                    
                    </Grid>
                </Box>
                :
                <Box
                    marginY={4}
                    padding={2}
                >
                    <Typography
                        color={ "primary" }
                        fontSize={ "medium" }
                        fontWeight={ "bold" }
                    >
                        Click on a User Name to view his Details
                    </Typography>
                </Box> 
            }
        </Paper>
    )
})

export default UserDetails;